import { Component, OnInit } from "@angular/core";

@Component({
  selector: "statistics-page",
  templateUrl: "./statistics-page.component.html",
  styleUrls: ["./statistics-page.component.scss"],
})
export class StatisticsPageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
