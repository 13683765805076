import { Component, OnInit } from '@angular/core';
import { EventInquiryHealthscreening } from "src/app/services/event.service";
import { EventApplicationService } from '../../../services/event-application.service';

@Component({
  selector: 'event-application-page',
  templateUrl: './event-application-page.component.html',
  styleUrls: ['./event-application-page.component.sass']
})
export class EventApplicationPageComponent implements OnInit {

  eventInquiryHealthScreenings: EventInquiryHealthscreening[];

  constructor(private eventApplcationService: EventApplicationService) { }

  ngOnInit() {
    this.eventApplcationService.getAvailableApplications().subscribe(res => {
      this.eventInquiryHealthScreenings = res.body;
    });
  }


}
