<form [formGroup]="healthScreeningForm">
  <div *ngIf="step === 1 || step === -1" class="row">
    <div class="col-sm-6 d-flex flex-column align-self-end">
      <div>
        <gib-select [placeholder]="'typeOfOffering'" [control]="healthScreeningForm.get('moduleType')" [options]="moduleOptions"></gib-select>
      </div>
      <div><gib-multiselect [placeholder]="'topic'" [control]="healthScreeningForm.get('topic')" [options]="topicOptions"></gib-multiselect></div>
    </div>
    <div class="col-sm-6 d-flex flex-column align-self-end">
      <div>
        <gib-checkbox class="mr-3 pt-3" [label]="'online'" [control]="healthScreeningForm.get('online')"></gib-checkbox>
        <gib-checkbox [label]="'onsite'" [control]="healthScreeningForm.get('onsite')"></gib-checkbox>
      </div>
      <div><gib-multiselect [placeholder]="'targetedCustomers'" [control]="healthScreeningForm.get('visibileForCompanynameAliases')" [options]="customerOptions"></gib-multiselect></div>
    </div>
  </div>
  <div *ngIf="step === 2 || step === -1">
    <div class="row">
      <div class="col-sm-12 d-flex flex-column align-self-end">
        <div class="mt-3">
          <h5>{{ "titleAndDescription" | translate }}</h5>
          <div class="d-flex justify-content-between">
            <gib-input style="width: 48%" [placeholder]="'moduleName'" [control]="healthScreeningForm.get('moduleName')"></gib-input>
            <gib-input style="width: 48%" [placeholder]="'moduleNameEn'" [control]="healthScreeningForm.get('moduleNameEn')"></gib-input>
          </div>
          <gib-textarea [placeholder]="'shortDescription'" [control]="healthScreeningForm.get('shortDescription')"></gib-textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 d-flex flex-column align-self-start">
        <div>
          <h5>{{ "contents" | translate }}</h5>
          <gib-input [placeholder]="'area'" [control]="healthScreeningForm.get('area')"></gib-input>
          <gib-input [placeholder]="'preventionPrincipal'" [control]="healthScreeningForm.get('preventionPrincipal')"></gib-input>
        </div>
      </div>
      <div class="col-sm-6 d-flex flex-column align-self-start">
        <div>
          <h5>{{ "generalInformation" | translate }}</h5>
          <div *ngIf="isHealthModule" class="row">
            <div class="col-md-12" [class.col-md-4]="isOnline" [class.col-md-6]="!isOnline">
              <gib-input *ngIf="isOnsite" [placeholder]="'recommendedOnsiteInterval'" [type]="'number'" [control]="healthScreeningForm.get('recommendedOnsiteInterval')"> </gib-input>
            </div>
            <div class="col-md-12" [class.col-md-4]="isOnline" [class.col-md-6]="!isOnline">
              <gib-input *ngIf="isOnsite" [placeholder]="'alternativeOnsiteInterval'" [type]="'number'" [control]="healthScreeningForm.get('alternativeOnsiteInterval')"></gib-input>
            </div>
            <div class="col-md-12" [class.col-md-4]="isOnsite">
              <gib-input *ngIf="isOnline" [placeholder]="'recommendedOnlineInterval'" [type]="'number'" [control]="healthScreeningForm.get('recommendedOnlineInterval')"></gib-input>
            </div>
          </div>
          <div *ngIf="!isHealthModule" class="row">
            <div class="col-md-12" [class.col-md-4]="isOnline && isOnsite" [class.col-md-6]="!isOnline || !isOnsite">
              <gib-input [placeholder]="'durationInMinutes'" [type]="'number'" [control]="healthScreeningForm.get('durationInMinutes')"></gib-input>
            </div>
            <div *ngIf="isOnline" class="col-md-12" [class.col-md-4]="isOnsite" [class.col-md-6]="!isOnsite">
              <gib-input *ngIf="isOnline" [placeholder]="'maxParticipantsOnline'" [type]="'number'" [control]="healthScreeningForm.get('maxParticipantsOnline')"></gib-input>
            </div>
            <div class="col-md-12" [class.col-md-4]="isOnline" [class.col-md-6]="!isOnline">
              <gib-input *ngIf="isOnsite" [placeholder]="'maxParticipantsOnsite'" [type]="'number'" [control]="healthScreeningForm.get('maxParticipantsOnsite')"></gib-input>
            </div>
          </div>
          <!-- <div *ngIf="isHealthModule" class="d-flex justify-content-between">
            <gib-input *ngIf="isOnsite" [style.width]="isOnline ? '30%' : '48%'" [placeholder]="'recommendedOnsiteInterval'" [type]="'number'" [control]="healthScreeningForm.get('recommendedOnsiteInterval')"></gib-input>
            <gib-input *ngIf="isOnsite" [style.width]="isOnline ? '30%' : '48%'" [placeholder]="'alternativeOnsiteInterval'" [type]="'number'" [control]="healthScreeningForm.get('alternativeOnsiteInterval')"></gib-input>
            <gib-input *ngIf="isOnline" [style.width]="isOnsite ? '30%' : '100%'" [placeholder]="'recommendedOnlineInterval'" [type]="'number'" [control]="healthScreeningForm.get('recommendedOnlineInterval')"></gib-input>
          </div> -->
          <!-- <div *ngIf="!isHealthModule" class="d-flex justify-content-between">
            <gib-input [style.width]="isOnline && isOnsite ? '30%' : '48%'" [placeholder]="'durationInMinutes'" [type]="'number'" [control]="healthScreeningForm.get('durationInMinutes')"></gib-input>
            <gib-input *ngIf="isOnline" [style.width]="isOnsite ? '30%' : '48%'" [placeholder]="'maxParticipantsOnline'" [type]="'number'" [control]="healthScreeningForm.get('maxParticipantsOnline')"></gib-input>
            <gib-input *ngIf="isOnsite" [style.width]="isOnline ? '30%' : '100%'" [placeholder]="'maxParticipantsOnsite'" [type]="'number'" [control]="healthScreeningForm.get('maxParticipantsOnsite')"></gib-input>
          </div> -->
          <div class="d-flex justify-content-between">
            <gib-input style="width: 58%" [placeholder]="'measuringDevice'" [control]="healthScreeningForm.get('measuringDevice')"></gib-input>
            <gib-input style="width: 38%" [placeholder]="'manufacturer'" [control]="healthScreeningForm.get('manufacturer')"></gib-input>
          </div>
          <gib-input [placeholder]="'implementationNotes'" [control]="healthScreeningForm.get('implementationNotes')"></gib-input>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 d-flex flex-column align-self-start">
        <div>
          <h5>{{ "onsiteRequirements" | translate }}</h5>
          <gib-input [placeholder]="'size'" [control]="healthScreeningForm.get('size')"></gib-input>
          <gib-input [placeholder]="'accessories'" [control]="healthScreeningForm.get('accessories')"></gib-input>
          <div class="d-flex justify-content-between">
            <gib-select style="width: 48%" [placeholder]="'power'" [control]="healthScreeningForm.get('power')" [options]="powerOptions"></gib-select>
            <gib-multiselect style="width: 48%" [placeholder]="'place'" [control]="healthScreeningForm.get('location')" [options]="locations"></gib-multiselect>
          </div>
        </div>
      </div>
      <div class="col-sm-6 d-flex flex-column align-self-start">
        <div>
          <h5>{{ "onlineRequirements" | translate }}</h5>
          <div *ngIf="!isOnline" class="alert alert-secondary d-flex flex-row">
            <i class="material-icons mr-3 align-self-center" style="cursor: pointer">info</i>
            <div>{{ "onlyAvailableInOnsite" | translate }}</div>
          </div>
          <div *ngIf="isOnline">
            <gib-input [placeholder]="'technicalRequirements'" [control]="healthScreeningForm.get('technicalRequirements')"></gib-input>
            <gib-input [placeholder]="'priorityPlatform'" [control]="healthScreeningForm.get('priorityPlatform')"></gib-input>
            <gib-multiselect [placeholder]="'onlinePlatform'" [control]="healthScreeningForm.get('onlinePlatform')" [options]="onlinePlatformOptions"></gib-multiselect>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 d-flex flex-column align-self-start">
        <div>
          <h5>{{ "mailNote" | translate }}</h5>
          <div class="mb-3">
            <gib-textarea [placeholder]="'onlineHint'" [control]="healthScreeningForm.get('onlineHint')" [hint]="'moduleHintOnForm' | translate"></gib-textarea>
          </div>
          <gib-input [placeholder]="'swayDe'" [control]="healthScreeningForm.get('swayDe')"></gib-input>
          <gib-input [placeholder]="'swayEn'" [control]="healthScreeningForm.get('swayEn')"></gib-input>
        </div>
      </div>
      <div class="col-sm-6 d-flex flex-column align-self-start">
        <div>
          <h5>{{ "moreInformation" | translate }}</h5>
          <gib-textarea [placeholder]="'additionalInformation'" [control]="healthScreeningForm.get('additionalInformation')"></gib-textarea>
        </div>
      </div>
    </div>
    <div *ngIf="step === -1">
      <div class="row">
        <div class="col-sm-12">
          <h5>{{ "addShortDescriptions" | translate }}</h5>
        </div>
      </div>
      <div class="row">
        <div *ngIf="isOnline" class="col-lg-6 col-sm-12 d-flex flex-column align-self-start">
          <div class="mt-3">
            <h6>{{ "descriptionsOnlineInfoPage" | translate }}</h6>
            <div class="mb-3">
              <attachement-handler [fileType]="'HEALTHSCREENING_ATTACHMENT_ONLINE'" [healthScreeningForm]="healthScreeningForm"></attachement-handler>
            </div>
          </div>
        </div>
        <div *ngIf="isOnsite" class="col-lg-6 col-sm-12 d-flex flex-column align-self-start">
          <div class="mt-3">
            <h6>{{ "descriptionsOnsiteInfoPage" | translate }}</h6>
            <div class="mb-3">
              <attachement-handler [fileType]="'HEALTHSCREENING_ATTACHMENT_ONSITE'" [healthScreeningForm]="healthScreeningForm"></attachement-handler>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="false" class="col-sm-6">
    <gib-input [placeholder]="'moduleName'" [control]="healthScreeningForm.get('moduleName')"></gib-input>
    <gib-input [placeholder]="'moduleNameEn'" [control]="healthScreeningForm.get('moduleNameEn')"></gib-input>
    <gib-select [placeholder]="'topic'" [control]="healthScreeningForm.get('topic')" [options]="topicOptions"></gib-select>
    <gib-textarea [placeholder]="'shortDescription'" [control]="healthScreeningForm.get('shortDescription')"></gib-textarea>
    <gib-textarea *ngIf="role !== 'gib_customer'" [placeholder]="'manual'" [control]="healthScreeningForm.get('manual')"></gib-textarea>
    <gib-input [placeholder]="'measuringDevice'" [control]="healthScreeningForm.get('measuringDevice')"></gib-input>
    <gib-textarea [placeholder]="'hint'" [control]="healthScreeningForm.get('hint')"></gib-textarea>
    <gib-textarea [placeholder]="'onlineHint'" [control]="healthScreeningForm.get('onlineHint')" [hint]="'moduleHintOnForm' | translate"></gib-textarea>
    <br />
    <br />
    <gib-input *ngIf="!showSwayAsLink" [placeholder]="'swayDe'" [control]="healthScreeningForm.get('swayDe')"></gib-input>
    <gib-input *ngIf="!showSwayAsLink" [placeholder]="'swayEn'" [control]="healthScreeningForm.get('swayEn')"></gib-input>
    <div *ngIf="showSwayAsLink">
      <p style="font-size: 10px; margin-bottom: 0px">{{ "swayDe" | translate }}</p>
      <a href="{{ healthScreeningForm.get('swayDe').value }}">{{ healthScreeningForm.get("swayDe").value }}</a>
      <p style="font-size: 10px; margin-bottom: 0px">{{ "swayEn" | translate }}</p>
      <a href="{{ healthScreeningForm.get('swayEn').value }}">{{ healthScreeningForm.get("swayEn").value }}</a>
    </div>
  </div>
  <div *ngIf="false" class="col-sm-6">
    <gib-expansion-panel class="top-panel" [title]="'location_preferences'" [hasError]="hasLocationPanelErrors()">
      <gib-input [placeholder]="'requiredArea'" [control]="healthScreeningForm.get('requiredArea')"></gib-input>
      <gib-select [placeholder]="'power'" [control]="healthScreeningForm.get('power')" [options]="powerOptions"></gib-select>
      <gib-input [placeholder]="'accessories'" [control]="healthScreeningForm.get('accessories')"></gib-input>
      <gib-multiselect [placeholder]="'place'" [control]="healthScreeningForm.get('location')" [options]="locations"></gib-multiselect>
      <gib-select *ngIf="healthScreeningForm.get('location').value.includes('ONLINE')" [placeholder]="'onlinePlatform'" [control]="healthScreeningForm.get('onlinePlatform')" [options]="onlinePlatformOptions"></gib-select>
    </gib-expansion-panel>
    <br />
    <gib-expansion-panel class="bottom-panel" [title]="'technical_specs'">
      <gib-input [placeholder]="'size'" [control]="healthScreeningForm.get('size')"></gib-input>
      <gib-input [placeholder]="'manufacturer'" [control]="healthScreeningForm.get('manufacturer')"></gib-input>
    </gib-expansion-panel>
    <br />
    <gib-expansion-panel [title]="'shortDescriptions'" *ngIf="healthScreeningForm.get('id').value">
      <attachement-handler [healthScreeningForm]="healthScreeningForm"></attachement-handler>
    </gib-expansion-panel>
  </div>
</form>
