<div class="row">
  <div class="col-xl-6 col-md-12">
    <div class="d-flex justify-content-between">
      <h4>{{ "customerData" | translate }}</h4>
      <img
        src="assets/icon/mail.png"
        *ngIf="userRole !== 'gib_customer' && !eventForm.get('disableEmailCommunication').disabled && (eventForm.get('disableEmailCommunication').value === null || !eventForm.get('disableEmailCommunication').value)"
        class="disableEmailCommunicationImg"
        placement="left"
        (click)="setDisableEmailCommunication(true)"
        [ngbTooltip]="'disableEmailCommunicationOff' | translate"
      />
      <img src="assets/icon/mail_grey.png" *ngIf="userRole !== 'gib_customer' && eventForm.get('disableEmailCommunication').disabled && (eventForm.get('disableEmailCommunication').value === null || !eventForm.get('disableEmailCommunication').value)" class="disableEmailCommunicationImgInactive" />
      <img
        src="assets/icon/mail_disabled.png"
        *ngIf="userRole !== 'gib_customer' && !eventForm.get('disableEmailCommunication').disabled && eventForm.get('disableEmailCommunication').value"
        class="disableEmailCommunicationImg"
        (click)="setDisableEmailCommunication(false)"
        placement="left"
        [ngbTooltip]="'disableEmailCommunicationOn' | translate"
      />
      <img src="assets/icon/mail_disabled_grey.png" *ngIf="userRole !== 'gib_customer' && eventForm.get('disableEmailCommunication').disabled && eventForm.get('disableEmailCommunication').value" class="disableEmailCommunicationImgInactive" />
      <i *ngIf="this.buid && showPrintButtons" class="material-icons printButton" (click)="openDownloadPdfConfirmation()" placement="left" [ngbTooltip]="'print' | translate">print</i>
    </div>

    <div class="box">
      <div class="row">
        <div class="col-xl-12">
          <gib-suggest-select
            *ngIf="(eventForm.get('channel').value === 'CALL' || eventForm.get('channel').value === 'BOOKING') && !eventForm.get('withoutCustomerSuggestSelect')"
            [placeholder]="'selectCustomer'"
            [control]="eventForm.get('customer').get('id')"
            [options]="customers"
            [disableDelete]="eventForm.get('state').value !== 'INITIAL'"
            (clear)="onClearCustomer()"
          ></gib-suggest-select>
        </div>
        <div class="col-xl-12">
          <gib-input [placeholder]="'companyname'" [control]="eventForm.get('customerCompanyName')"></gib-input>
        </div>
        <div class="col-xl-12" *ngIf="eventForm && !eventForm.get('customerSalutation').valid && eventForm.get('customerSalutation').touched && !eventForm.get('customerSalutation').disabled">
          <gib-radio-button-group-orange [label]="'salutation'" [control]="eventForm.get('customerSalutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
        </div>
        <div class="col-xl-12" *ngIf="eventForm && (eventForm.get('customerSalutation').valid || !eventForm.get('customerSalutation').touched || eventForm.get('customerSalutation').disabled)">
          <gib-radio-button-group [label]="'salutation'" [control]="eventForm.get('customerSalutation')" [valueLabels]="salutationOptions"></gib-radio-button-group>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'firstname'" [control]="eventForm.get('customerFirstname')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'lastname'" [control]="eventForm.get('customerLastname')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input id="inputPhone" [placeholder]="'phonenumber'" [control]="eventForm.get('customerPhone')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'email'" [control]="eventForm.get('customerMail')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'region'" [control]="eventForm.get('customerRegion')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'teamNr'" [control]="eventForm.get('customerTeamnumber')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'street'" [control]="eventForm.get('customerAddress').get('street')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'streetno'" [control]="eventForm.get('customerAddress').get('number')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'city'" [control]="eventForm.get('customerAddress').get('city')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'zip'" [control]="eventForm.get('customerAddress').get('zip')"></gib-input>
        </div>
        <div *ngIf="isTK" class="col-xl-12">
          <gib-radio-button-group [label]="'companyBonus' | translate" [control]="eventForm.get('companyBonus')" [valueLabels]="booleanOptions"></gib-radio-button-group>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-6 col-md-12">
    <div class="d-flex justify-content-between">
      <h4>{{ "contactPersonByHealthCompany" | translate }}</h4>
      <i class="copyButton" *ngIf="!eventForm.get('customerContactSalutation').disabled" class="material-icons" (click)="copyCustomerInfo()" placement="left" [ngbTooltip]="'takeoverCustomerDataAsContactPerson' | translate">swap_horiz</i>
    </div>

    <div class="box">
      <div class="row">
        <div class="col-xl-12" *ngIf="this.showCustomerContactSearchField">
          <gib-suggest-select [placeholder]="'findCustomerContact'" [control]="eventForm.get('customerContactSearch')" [options]="customerContactValueLabels"></gib-suggest-select>
        </div>
        <div class="col-xl-12">
          <gib-input [placeholder]="'companyname'" [control]="eventForm.get('customerContactCompanyName')"></gib-input>
        </div>
        <div class="col-xl-12" *ngIf="eventForm && !eventForm.get('customerContactSalutation').valid && eventForm.get('customerContactSalutation').touched && !eventForm.get('customerContactSalutation').disabled">
          <gib-radio-button-group-orange [label]="'salutation'" [control]="eventForm.get('customerContactSalutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
        </div>
        <div class="col-xl-12">
          <gib-radio-button-group
            *ngIf="eventForm && (eventForm.get('customerContactSalutation').valid || !eventForm.get('customerContactSalutation').touched || eventForm.get('customerContactSalutation').disabled)"
            [label]="'salutation'"
            [control]="eventForm.get('customerContactSalutation')"
            [valueLabels]="salutationOptions"
          ></gib-radio-button-group>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'firstname'" [control]="eventForm.get('customerContactFirstname')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'lastname'" [control]="eventForm.get('customerContactLastname')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input id="inputPhone" [placeholder]="'phonenumber'" [control]="eventForm.get('customerContactPhone')"></gib-input>
        </div>
        <div class="col-xl-6 col-md-6 col-sm-12">
          <gib-input [placeholder]="'email'" [control]="eventForm.get('customerContactMail')"></gib-input>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-between">
      <h4>{{ "bookingCopyRecepientMail" | translate }}</h4>
      <div *ngIf="eventForm.get('channel').value === 'FORM'">
        <img src="assets/icon/save.png" class="saveImg" (click)="updateMailReceipients()" placement="left" [ngbTooltip]="'updateMailReceipientsInProfile' | translate" />
        <i class="material-icons" (click)="updateEmptyMailReceipients()" placement="left" [ngbTooltip]="'deleteMailReceipientsInProfile' | translate">clear</i>
      </div>
    </div>
    <div class="box">
      <div class="row">
        <div class="col-xl-12">
          <gib-multi-input [placeholder]="'email'" [control]="eventForm.get('bookingCopyRecepientMail')" [tempControl]="eventForm.get('bookingCopyRecepientMailTemp')"></gib-multi-input>
        </div>
      </div>
    </div>
  </div>
</div>
