<div class="d-flex">
  <mat-card>
    <mat-card-content>
      <div class="img-wrapper" *ngIf="imageUrls && imageUrls.length < 2">
        <img src="{{ imageUrls[0] }}" />
      </div>
      <div class="slideshow-wrapper customerHsInfoForm" *ngIf="imageUrls && imageUrls.length > 1">
        <gib-slideshow [imageUrls]="imageUrls"></gib-slideshow>
        <!-- <slideshow [minHeight]="'350px'" dotColor="var(--secondary)" showDots="true" [imageUrls]="imageUrls"></slideshow> -->
      </div>
      <div class="row">
        <div class="col-12">
          <h4>{{ "shortDescription" | translate }}</h4>
          <div class="box">
            <p>{{ healthscreeningForm.get("shortDescription").value }}</p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h4>{{ "contents" | translate }}</h4>
          <div class="box">
            <div class="row">
              <div class="col-12">
                <p>
                  <b>{{ "area" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("area").value | translate }}
                  <br />
                  <br />
                  <b>{{ "preventionPrincipal" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("preventionPrincipal").value }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-6">
          <h4>{{ "generalInformation" | translate }}</h4>
          <div class="box">
            <div class="row">
              <div class="col-6">
                <p>
                  <span *ngIf="isHealthModule && isOnsite">
                    <b>{{ "recommendedOnsiteIntervalFull" | translate }}</b>
                    <br />
                    {{ "recommendedOnsiteIntervalDisplay" | translate : { value: healthscreeningForm.get("recommendedOnsiteInterval").value } }}
                    <br />
                    <br />
                  </span>
                  <span *ngIf="!isHealthModule">
                    <b>{{ "duration" | translate }}</b>
                    <br />
                    {{ "durationDisplay" | translate : { value: healthscreeningForm.get("durationInMinutes").value } }}
                    <br />
                    <br />
                  </span>

                  <b>{{ "measuringDevice" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("measuringDevice").value }}
                  <br />
                  <br />
                  <b>{{ "implementationNotes" | translate }}</b>
                  {{ healthscreeningForm.get("implementationNotes").value }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <span *ngIf="isHealthModule">
                    <b>{{ "recommendedOnlineIntervalFull" | translate }}</b>
                    <br />
                    <span *ngIf="!isOnline">
                      {{ "/" }}
                    </span>
                    <span *ngIf="isOnline">
                      {{ "recommendedOnlineIntervalDisplay" | translate : { value: healthscreeningForm.get("recommendedOnlineInterval").value } }}
                    </span>
                    <br />
                    <br />
                  </span>
                  <span *ngIf="!isHealthModule">
                    <b>{{ "maxParticipants" | translate }}</b>
                    <br />
                    <span *ngIf="isOnline">{{ healthscreeningForm.get("maxParticipantsOnline").value }} ({{ "online" | translate }})</span>
                    <span *ngIf="isOnline && isOnsite">, </span>
                    <span *ngIf="isOnsite">{{ healthscreeningForm.get("maxParticipantsOnsite").value }} ({{ "onsite" | translate }})</span>
                    <br />
                    <br />
                  </span>

                  <b>{{ "manufacturer" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("manufacturer").value }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h4>{{ "onsiteRequirements" | translate }}</h4>
          <div class="box">
            <div class="row">
              <div class="col-6">
                <p>
                  <b>{{ "size" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("size").value }}
                  <br /><br />
                  <b>{{ "power" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("power").value ? ("REQUIRED" | translate) : ("NOT_REQUIRED" | translate) }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b>{{ "accessories" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("accessories").value }}
                  <br /><br />
                  <b>{{ "place" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("location").value }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h4>{{ "onlineRequirements" | translate }}</h4>
          <div class="box">
            <div *ngIf="!isOnline" class="alert alert-secondary d-flex flex-row">
              <i class="material-icons mr-3 align-self-center" style="cursor: pointer">info</i>
              <div>{{ "onlyAvailableInOnsite" | translate }}</div>
            </div>
            <p *ngIf="isOnline">
              <b>{{ "technicalRequirements" | translate }}</b>
              <br />
              {{ healthscreeningForm.get("technicalRequirements").value }}
              <br />
              <br />
              <b>{{ "priorityPlatform" | translate }}</b>
              <br />
              {{ healthscreeningForm.get("priorityPlatform").value }}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h4>{{ "descriptionsOnsite" | translate }}</h4>
          <div class="box">
            <attachement-handler [fileType]="'HEALTHSCREENING_ATTACHMENT_ONSITE'" [healthScreeningForm]="healthscreeningForm"></attachement-handler>
          </div>
        </div>
        <div class="col-6">
          <h4>{{ "descriptionsOnline" | translate }}</h4>
          <div class="box">
            <attachement-handler [fileType]="'HEALTHSCREENING_ATTACHMENT_ONLINE'" [healthScreeningForm]="healthscreeningForm"></attachement-handler>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <h4>{{ "furtherInformation" | translate }}</h4>
          <div class="box">
            <span *ngIf="healthscreeningForm.get('additionalInformation').value">{{ healthscreeningForm.get("additionalInformation").value }}</span>
            <span *ngIf="!healthscreeningForm.get('additionalInformation').value">/</span>
          </div>
        </div>
      </div>

      <!-- <div class="row">
        <div class="col-6">
          <h4>{{ "location_preferences" | translate }}</h4>
          <div class="box">0
            <div class="row">
              <div class="col-6">
                <p>
                  <b>{{ "requiredArea" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("requiredArea").value | translate }}
                  <br />
                  <br />
                  <b>{{ "power" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("power").value | translate }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b>{{ "accessories" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("accessories").value | translate }}
                  <br />
                  <br />
                  <b>{{ "place" | translate }}</b>
                  <br />
                  {{ healthscreeningForm.get("location").value }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <h4>{{ "shortDescriptions" | translate }}</h4>
          <div class="box">
            <attachement-handler [healthScreeningForm]="healthscreeningForm"></attachement-handler>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <h4>{{ "technical_specs" | translate }}</h4>
          <div class="box">
            <div class="row">
              <div class="col-6">
                <p>
                  <b>{{ "manufacturer" | translate }}</b>
                  {{ healthscreeningForm.get("manufacturer").value }}
                </p>
              </div>
              <div class="col-6">
                <p>
                  <b>{{ "size" | translate }}</b>
                  {{ healthscreeningForm.get("size").value }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6"></div>
      </div> -->
      <div class="center">
        <gib-icon-button [icon]="'book'" [text]="'book'" [type]="'primary'" (click)="addToCartEmit()"></gib-icon-button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
