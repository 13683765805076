<form id="employee_profile" *ngIf="formGroup" [formGroup]="formGroup">
  <div class="row">
    <div class="col-sm-6">
      <h6>{{'masterData' | translate}}</h6>
      <gib-input [placeholder]="'firstname'" [control]="formGroup.get('firstname')"></gib-input>
      <gib-input [placeholder]="'lastname'" [control]="formGroup.get('lastname')"></gib-input>
      <gib-input [placeholder]="'username'" [control]="formGroup.get('username')"></gib-input>
      <gib-input [placeholder]="'email'" [control]="formGroup.get('email')"></gib-input>
      <gib-input [placeholder]="'phonenumber'" [control]="formGroup.get('phonenumber')"></gib-input>
    </div>
    <div class="col-sm-5">
      <h6>{{'ADRESS' | translate}}</h6>
      <gib-input [placeholder]="'street'" [control]="formGroup.get('addresses').get('0').get('street')"></gib-input>
      <gib-input [placeholder]="'streetno'" [control]="formGroup.get('addresses').get('0').get('number')"></gib-input>
      <gib-input [placeholder]="'zip'" [control]="formGroup.get('addresses').get('0').get('zip')"></gib-input>
      <gib-input [placeholder]="'city'" [control]="formGroup.get('addresses').get('0').get('city')"></gib-input>
    </div>
    <div class="col-sm-1">
      <i id="edit_icon" class="material-icons edit-icon" (click)="startEditMode()" *ngIf="!editMode">edit</i>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <gib-fieldset [title]="'CAR'">
        <gib-checkbox [label]="'DRIVING_LICENSE'" [control]="formGroup.get('carDrivingLicense')"></gib-checkbox>
        <br />
        <gib-checkbox [label]="'KFZ'" [control]="formGroup.get('carKfz')"></gib-checkbox>
        <br />
        <gib-checkbox id="driving_license_copy" [label]="'DRIVING_LICENSE_COPY'" [control]="formGroup.get('carDrivingLicenseCopy')"></gib-checkbox>
        <warning-icon id="driving_license_copy_warning" *ngIf="showWarningIcon()" [placement]="'right'" [title]="'fileupload_required'" [text]="'drivingLicense_upload_text'"></warning-icon>
        <div *ngIf="showStatus()">
          {{'file' | translate}}: <b>
            {{formGroup.get('carDrivingLicenseCopyFile').value.fileName}}
          </b>
          <i class="material-icons" (click)="downloadFile()">get_app</i>
        </div>
        <gib-fileupload id="driving_license_copy_upload" *ngIf="showFileUpload()" [control]="formGroup.get('carDrivingLicenseCopyFile')" [type]="'DRIVING_LICENSE'" [maxFileSize]="5" (fileUploaded)="checkForUploadedDrivingLicense()"></gib-fileupload>
        <div id="driving_license_copy_upload_status" *ngIf="showStatus()">
          <span [ngStyle]="{}">
            {{'status' | translate}}: <b>{{(formGroup.get('carDrivingLicenseCopyFile').value.status|translate)}}</b></span>
        </div>
      </gib-fieldset>
    </div>
  </div>
</form>
