import { SelectOptions } from "./../../../../utils/select-options";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";
import { FormHelper } from "./../../../../helper/form.helper";
import { CustomValidators } from "src/app/utils/custom-validator";

@Component({
  selector: "customer-profile",
  templateUrl: "./customer-profile.component.html",
  styleUrls: ["./customer-profile.component.scss"],
})
export class CustomerProfileComponent implements OnInit {
  @Input() editMode: boolean;
  @Output() editModeChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() form: UntypedFormGroup;
  salutationOptions = SelectOptions.salutationOptions;

  constructor(private formHelper: FormHelper, private customValidators: CustomValidators) {}

  ngOnInit() {
    if (this.form) {
      this.form.addControl("bookingCopyRecepientMailTemp", new UntypedFormControl("", [this.customValidators.email, this.customValidators.specialCharacterValidator]));
      this.form.get("bookingCopyRecepientMailTemp").disable();
    }
  }

  startEditMode() {
    this.editMode = true;
    this.editModeChange.emit(true);
    this.formHelper.enableControls(this.form);
    this.formHelper.disableControlsByName(this.form, ["firstname", "lastname", "email", "salutation"]);
  }
}
