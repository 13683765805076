import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { KeycloakUser } from "./keycloak-connector.service";
import { Observable } from "rxjs";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import * as moment from "moment";
import { EnvService } from "./env.service";

export declare type CalendarEntryType = "AVAILABLE" | "UNAVAILABLE" | "APPOINTED" | "UNDETERMINED" | "AVAILABLE_NOT_FITTING";

export class CalendarEntryDto {
  id: number;
  userId: {};
  role: string;
  date: Date;
  trainerAvailabilityTimeFrom: string;
  trainerAvailabilityTimeTo: string;
  trainerAvailabilityDistance: number;
  entryType: CalendarEntryType;
  comment: string;
  // lastUpdated: Date;
  // createdDate: Date;
  selectedTrainerUsername: string;
  eihsDto: CalendarEihsDto[];
  hasMultipleEvents: boolean;

  constructor() {
    this.id = null;
    this.userId = null;
    this.role = "";
    this.date = moment().set({ hour: 12, minute: 0, second: 0, millisecond: 0 }).toDate();
    this.trainerAvailabilityTimeFrom = "";
    this.trainerAvailabilityTimeTo = "";
    this.trainerAvailabilityDistance = 0;
    this.entryType = "AVAILABLE";
    this.comment = "";
    this.selectedTrainerUsername = "";
    this.eihsDto = [];
    this.hasMultipleEvents = false;
  }
}

export class CalendarEihsDto {
  eventStartTime: string;
  eventEndTime: string;
  eventId: number;
  eihsId: number;
  modulePresentation: boolean;
  moduleEnglish: boolean;
  anonymEvaluation: boolean;
  customerCompanynameAlias: string;
  city: string;
  moduleName: string;
  distanceFromBerlin: number;
  distanceFromHamburg: number;
  locationDistance: number;
  moduleTimeFrom: string;
  moduleTimeTo: string;

  constructor() {
    this.eventStartTime = "";
    this.eventEndTime = "";
    this.eventId = null;
    this.eihsId = null;
    this.modulePresentation = false;
    this.moduleEnglish = false;
    this.anonymEvaluation = false;
    this.moduleName = "";
    this.customerCompanynameAlias = "";
    this.city = "";
    this.distanceFromBerlin = 0;
    this.distanceFromHamburg = 0;
    this.locationDistance = 0;
    this.moduleTimeFrom = "";
    this.moduleTimeTo = "";
  }
}

export class TrainerSelectionDTO {
  employeeId: string;
  employeeFullname: string;
  availability: CalendarEntryType;
}

@Injectable({
  providedIn: "root",
})
export class CalendarService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private formbuilder: UntypedFormBuilder, private envService: EnvService) {}

  create(entries: CalendarEntryDto[]): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/calendar/create", entries, this.config);
  }

  update(entry: CalendarEntryDto): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/calendar/update", entry, this.config);
  }

  delete(entryId: number): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/calendar/delete/" + entryId, this.config);
  }

  getMyEntries(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/calendar/getMyCalendarEntries", this.config);
  }

  getEntriesForUser(userId: string): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/calendar/getCalendarEntriesForUser?userId=" + userId, this.config);
  }

  getAllCalendarEntries(from: string, to: string): Observable<HttpConfig> {
    const dateFrom = moment(from).format("YYYY-MM-DD");
    const dateTo = moment(to).format("YYYY-MM-DD");
    return this.http.get(this.envService.backendUrl + "/calendar/getAllCalendarEntries?from=" + dateFrom + "&to=" + dateTo, this.config);
  }

  postExcel(entries: CalendarEntryDto[], headerColumns: any[]): Observable<any> {
    const data = { entries, headerColumns };
    return this.http.post(this.envService.backendUrl + "/calendar/getTrainerAvailabilityExcelExport", data, { responseType: "blob" });
  }

  mapObjectToForm(entry: CalendarEntryDto): UntypedFormGroup {
    const form = this.formbuilder.group(entry);
    return form;
  }

  mapFormToObject(form: UntypedFormGroup): CalendarEntryDto {
    const entry = form.getRawValue();
    return entry;
  }

  mapFormToObjects(form: UntypedFormGroup): CalendarEntryDto[] {
    const startDate = moment(form.get("date").value);
    const endDate = moment(form.get("dateTo").value).set({ hour: 12, minute: 0, second: 0, millisecond: 0 });
    const diff = endDate.diff(startDate, "days");
    const entries: CalendarEntryDto[] = [];
    for (let i = 0; i <= diff; i++) {
      const date = moment(startDate).add(i, "day").toDate();
      if (this.isDayIncluded(form, date)) {
        const entry = this.mapFormToObject(form);
        entry.date = moment(startDate).add(i, "day").toDate();
        entries.push(entry);
      }
    }
    return entries;
  }

  isDayIncluded(form: UntypedFormGroup, date: any) {
    const mondays = form.get("mondays").value;
    const tuesdays = form.get("tuesdays").value;
    const wednesdays = form.get("wednesdays").value;
    const thursdays = form.get("thursdays").value;
    const fridays = form.get("fridays").value;
    const saturdays = form.get("saturdays").value;
    const sundays = form.get("sundays").value;
    return (
      (mondays && moment(date).isoWeekday() === 1) ||
      (tuesdays && moment(date).isoWeekday() === 2) ||
      (wednesdays && moment(date).isoWeekday() === 3) ||
      (thursdays && moment(date).isoWeekday() === 4) ||
      (fridays && moment(date).isoWeekday() === 5) ||
      (saturdays && moment(date).isoWeekday() === 6) ||
      (sundays && moment(date).isoWeekday() === 7)
    );
  }
}
