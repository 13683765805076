<h1> {{ 'CUSTOMER' | translate }} </h1>
<div class="row">
  <div class="{{selectedCustomer ? 'col-sm-12 col-md-12 col-lg-6' : 'col-sm-12 col-md-12 col-lg-12'}}">
    <customer-table #custmrTable [customers]="customers" (customerSelected)="setSelectedCustomer($event)" (addCustomerClicked)="openAddCustomerCard()" (customerDeleted)="getUsers()"></customer-table>
  </div>
  <div *ngIf="selectedCustomer" class="col-sm-12 col-md-12 col-lg-6 customer-details">
    <!-- <div class="add-customer-wrapper"> -->
      <div class="sticky-top" style="top: 50px">
        <add-customer [customer]="selectedCustomer" [addressType]="'BILLING'" (customerAdded)="getUsers()"></add-customer>
      </div>
    <!-- </div> -->
  </div>
</div>