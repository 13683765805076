import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { EventService } from "../../../../services/event.service";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "grid-event-abort-button",
  templateUrl: "./grid-event-abort-button.component.html",
  styleUrls: ["./grid-event-abort-button.component.sass"],
})
export class GridEventAbortButtonComponent implements ICellRendererAngularComp {
  eventId: number;
  eihsId: number;
  refreshData: any;
  showButton = false;
  eventCompleted: boolean;

  constructor(private eventService: EventService, private dialogService: GibDialogService, private translateService: TranslateService) {}

  agInit(params: any) {
    this.refreshData = params.refreshData;
    this.eventId = params.data.eventId;
    this.eihsId = params.data.eventInquiryHealthscreeningId;
    this.showButton = params.showButton;
    if (params.data.state === "COMPLETED") {
      this.eventCompleted = true;
    }
  }

  refresh() {
    return true;
  }

  abortEventClicked() {
    const confirmationTitle = this.translateService.instant("abortEventTitle");
    const confirmationText = this.translateService.instant("abortEventText");
    this.dialogService.openCancelationDialog(confirmationTitle, confirmationText).subscribe((res) => {
      if (res) {
        this.abortEvent(res);
      }
    });
  }

  abortModuleClicked() {
    const confirmationTitle = this.translateService.instant("abortModuleTitle");
    const confirmationText = this.translateService.instant("abortModuleText");
    this.dialogService.openCancelationDialog(confirmationTitle, confirmationText).subscribe((res) => {
      if (res) {
        this.abortModule(res);
      }
    });
  }

  abortEvent(res: any) {
    this.eventService.cancel(this.eventId, res.reason, res.cancelationDate).subscribe((res) => {
      this.refreshData();
    });
  }

  abortModule(res: any) {
    this.eventService.cancelModule(this.eventId, res.reason, this.eihsId, res.cancelationDate).subscribe((res) => {
      this.refreshData();
    });
  }

  toggleShift(obj, event) {
    if (event.shiftKey) {
      this.abortEventClicked();
    } else {
      this.abortModuleClicked();
    }
  }
}
