<div class="d-flex justify-content-end">
  <button type="button" class="btn btn-outline" routerLink="/">{{ "back" | translate }}</button>
</div>
<div class="row d-flex justify-content-between">
  <h2>
    {{ "statistics" | translate }}
    <br />
    <br />
  </h2>
  <div class="w-100" style="margin-bottom: 10px">
    {{ "statisticsSoonToCome" | translate }}
  </div>
</div>
