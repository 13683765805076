<mat-card>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <mat-label><b>{{eventInquiryHealthScreening.healthscreening.moduleName}}</b></mat-label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2">
        {{'eventDate' | translate}}:
      </div>
      <div class="col-sm-9">
        {{this.eventInquiryHealthScreening.eventInquiry.eventDate | amDateFormat:'DD.MM.YYYY' + getFromToString() }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2">
        {{'event_location' | translate}}:
      </div>
      <div class="col-sm-4">
        {{ createAddressString()}}
      </div>
      <div class="col-sm-6">
        <div [@visibility]="(showButtons) ? 'visible': 'hidden'">
          <button class="btn btn-primary" (click)="applyForEventClicked()">
            <i class="material-icons">assignment_turned_in</i>
            {{'apply' | translate}}
          </button>
        </div>
        <div [@visibility]="!showButtons ? 'visible': 'hidden'">
          <p><b>{{'yourApplicationRunning' | translate}}</b></p>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<hr>
<br>
