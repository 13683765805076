import { Component, Inject, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as moment from "moment";

export interface DialogCancelationData {
  title: string;
  text: string;
}

@Component({
  selector: "gib-cancelation-dialog",
  templateUrl: "./gib-cancelation-dialog.component.html",
  styleUrls: ["./gib-cancelation-dialog.component.sass"],
})
export class GibCancelationDialogComponent {
  reason: UntypedFormControl = new UntypedFormControl("", [Validators.required]);
  cancelationDate: UntypedFormControl = new UntypedFormControl(moment(new Date()), [Validators.required]);

  constructor(public dialogRef: MatDialogRef<GibCancelationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogCancelationData) {}

  cancelAction(): void {
    this.dialogRef.close();
  }

  confirmAction(): void {
    if (this.reason.valid && this.cancelationDate.valid) {
      this.dialogRef.close({ reason: this.reason.value, cancelationDate: this.cancelationDate.value });
    } else {
      if (!this.cancelationDate.valid) {
        this.cancelationDate.markAsTouched();
      }
      if (!this.reason.valid) {
        this.reason.markAsTouched();
      }
    }
  }
}
