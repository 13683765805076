<div class="row">
  <div class="col-12">
    <h2 *ngIf="isLoggedIn">{{ "gibHomeWelcome" | translate }}</h2>
    <div *ngIf="!isLoggedIn" class="center">
      <br />
      <h2>{{ "gibHomeWelcome" | translate }}</h2>
      <br />
      <button type="button" class="btn btn-primary loginButton" (click)="login()">{{ "login" | translate }}</button>
    </div>
  </div>
</div>

<!-- customer view -->
<div class="row" *ngIf="role === 'gib_customer'">
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="createEventInquiry" linkTo="/event-inquiry-page/FORM" image="/assets/images/homepage/event_inquiry.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="myEvents" linkTo="/my-events" image="/assets/images/homepage/my_events.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="statistics" linkTo="/statistics" image="/assets/images/homepage/pro_statistics.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="healthscreeningOverview" linkTo="/customer-health-screening-overview-page/INDOOR_OUTDOOR" image="/assets/images/homepage/healthscreening.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="onlineHealthscreeningOverview" linkTo="/customer-health-screening-overview-page/ONLINE" image="/assets/images/homepage/online_healthscreening.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="interactiveWebinars" linkTo="/customer-health-screening-overview-page/WEBINAR" image="/assets/images/homepage/interaktive_webinare_vortaege.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="activeBreaks" linkTo="/customer-health-screening-overview-page/ACTIVE_BREAK" image="/assets/images/homepage/aktivpausen.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="faqNavigation" linkTo="/faq" image="/assets/images/homepage/faq.jpg"></home-tile>
  </div>
</div>

<!-- trainer view -->
<div class="row" *ngIf="role === 'gib_trainer'">
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="myTasks" linkTo="/my-tasks" image="/assets/images/homepage/event_inquiry.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="calendar" linkTo="/trainer-calendar" image="/assets/images/homepage/my_events.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="faqNavigation" linkTo="/faq" image="/assets/images/homepage/faq.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="healthscreeningOverview" linkTo="/customer-health-screening-overview-page/INDOOR_OUTDOOR" image="/assets/images/homepage/healthscreening.jpg"></home-tile>
  </div>
  <div class="col-lg-4 col-md-6 col-sm-12">
    <home-tile headername="onlineHealthscreeningOverview" linkTo="/customer-health-screening-overview-page/ONLINE" image="/assets/images/homepage/online_healthscreening.jpg"></home-tile>
  </div>
</div>
