import { Component, OnInit } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { GibDialogService } from "../../../components/dialogs/gib-dialog.service";
import { FormHelper } from "../../../helper/form.helper";
import { Customer, CustomerService } from "../../../services/customer.service";
import { SelectOptions } from "../../../utils/select-options";
import { KeycloakService } from "keycloak-angular";
import { EnvService } from "src/app/services/env.service";
import { CustomValidators } from "src/app/utils/custom-validator";

@Component({
  selector: "customer-registration-submit-page",
  templateUrl: "./customer-registration-submit-page.component.html",
  styleUrls: ["./customer-registration-submit-page.component.scss"],
})
export class CustomerRegistrationSubmitPageComponent implements OnInit {
  form;
  link: string;
  urlHasError = false;
  salutationOptions = SelectOptions.salutationOptions;
  registrationCompleted = false;

  constructor(
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private dialogService: GibDialogService,
    private translateService: TranslateService,
    private formHelper: FormHelper,
    private keycloakService: KeycloakService,
    private envService: EnvService,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((values) => {
      const customerId = values["business-key"];
      this.customerService.getCustomer(customerId).subscribe(
        (customer) => {
          this.form = this.initForm(customer);
        },
        (error) => {
          this.urlHasError = true;
        }
      );
    });
  }

  submitRegistration() {
    if (this.form.valid) {
      const customer = this.customerService.mapFormToCustomer(this.form);
      customer.addresses = [];
      this.customerService.submitRegistration(customer).subscribe(() => {
        const title = this.translateService.instant("userRegistratedSuccessTitle");
        const text = this.translateService.instant("userRegistratedSuccessText");
        this.dialogService.openDialog(title, text, "success", () => {
          this.keycloakService.login({ redirectUri: this.envService.authRedirectUrl });
        });
        this.formHelper.disableControls(this.form);
        this.registrationCompleted = true;
      });
    } else {
      const errorMessages = this.formHelper.createFieldErrorMessage(this.form);
      this.dialogService.openErrorDialog("customer_signup_error_title", errorMessages);
    }
  }

  initForm(customer: Customer) {
    const customerForm = this.customerService.mapCustomerToForm(customer);
    customerForm.addControl("passwordConfirmation", new UntypedFormControl(""));
    customerForm.addControl("gdprChecked", new UntypedFormControl(""));
    customerForm.get("firstname").setValidators([Validators.required]);
    customerForm.get("lastname").setValidators([Validators.required]);
    customerForm.get("username").setValidators([Validators.required]);
    customerForm.get("phonenumber").setValidators([Validators.required]);
    customerForm.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    customerForm.get("salutation").setValidators([Validators.required]);
    customerForm.get("password").setValidators([Validators.required]);
    customerForm.setValidators(this.formHelper.checkPasswords);
    customerForm.get("gdprChecked").setValidators([Validators.requiredTrue]);
    return customerForm;
  }
}
