<mat-tab-group>
  <mat-tab label="{{ 'masterData' | translate }}">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form">
          <div class="row">
            <div class="col-sm-6 col-md-6">
              <div class="row">
                <div class="col-sm-6">
                  <h6>{{ "masterData" | translate }}</h6>
                  <gib-radio-button-group-orange *ngIf="form && !form.get('salutation').valid && form.get('salutation').touched && !form.get('salutation').disabled" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group-orange>
                  <gib-radio-button-group *ngIf="form && (form.get('salutation').valid || !form.get('salutation').touched || form.get('salutation').disabled)" [label]="'salutation'" [control]="form.get('salutation')" [valueLabels]="salutationOptions"></gib-radio-button-group>
                  <gib-input [placeholder]="'firstname'" [control]="form.get('firstname')"></gib-input>
                  <gib-input [placeholder]="'lastname'" [control]="form.get('lastname')"></gib-input>
                  <gib-input [placeholder]="'username'" [control]="form.get('username')"></gib-input>
                  <gib-input [placeholder]="'email'" [control]="form.get('email')"></gib-input>
                  <gib-input [placeholder]="'phonenumber'" [control]="form.get('phonenumber')"></gib-input>
                </div>
                <div class="col-sm-6">
                  <h6>{{ "ADRESS" | translate }}</h6>
                  <gib-input [placeholder]="'street'" [control]="form.get('addresses').get('0').get('street')"></gib-input>
                  <gib-input [placeholder]="'streetno'" [control]="form.get('addresses').get('0').get('number')"></gib-input>
                  <gib-input [placeholder]="'zip'" [control]="form.get('addresses').get('0').get('zip')"></gib-input>
                  <gib-input [placeholder]="'city'" [control]="form.get('addresses').get('0').get('city')"></gib-input>
                </div>

                <mat-divider></mat-divider>

                <div class="col-sm-12">
                  <h6>{{ "FURTHER_DETAILS" | translate }}</h6>
                </div>
                <div class="col-sm-6">
                  <gib-fieldset [title]="''">
                    <gib-select [placeholder]="'status'" [control]="form.get('state')" [options]="states"></gib-select>
                    <gib-multiselect [placeholder]="'place'" [control]="form.get('location')" [options]="locations"></gib-multiselect>
                    <mat-divider></mat-divider>
                    <gib-checkbox [label]="'blacklisted'" [control]="form.get('blacklisted')"></gib-checkbox>
                  </gib-fieldset>
                  <gib-fieldset [title]="'CAR'">
                    <gib-checkbox [label]="'DRIVING_LICENSE'" [control]="form.get('carDrivingLicense')"></gib-checkbox>
                    <br />
                    <gib-checkbox [label]="'KFZ'" [control]="form.get('carKfz')"></gib-checkbox>
                    <br />
                    <gib-checkbox [label]="'DRIVING_LICENSE_COPY'" [control]="form.get('carDrivingLicenseCopy')"></gib-checkbox>
                  </gib-fieldset>
                  <gib-fieldset [title]="'SKILLS'">
                    <gib-checkbox [label]="'presGerman'" [control]="form.get('gerPresentation')"></gib-checkbox>
                    <br />
                    <gib-checkbox [label]="'presEnglish'" [control]="form.get('engPresentation')"></gib-checkbox>
                    <br />
                    <gib-checkbox [label]="'ADVICE'" [control]="form.get('engAdvice')"></gib-checkbox>
                  </gib-fieldset>
                </div>
                <div class="col-sm-6">
                  <gib-textarea [placeholder]="'qualifications'" [control]="form.get('qualifications')"></gib-textarea>
                  <mat-divider></mat-divider>
                  <gib-checkbox [label]="'preventionCourse'" [control]="form.get('preventionCourse')"></gib-checkbox>
                  <mat-divider></mat-divider>
                  <gib-textarea [placeholder]="'COMMENT'" [control]="form.get('comment')"></gib-textarea>
                </div>
              </div>
            </div>

            <div class="col-sm-6 col-md-6">
              <div class="col-sm-12 healthscreening-mapper">
                <h6>{{ "healthscreening-assignment" | translate }}</h6>
                <gib-drag-and-drop-box [availableItems]="availableHealthscreenings" [(assignedItems)]="_employee.assignedHealthscreenings" [shownKey]="'moduleName'" [availableLabel]="'allModules'" [assignedLabel]="'trainerModules'"></gib-drag-and-drop-box>
              </div>
            </div>
          </div>
        </form>
      </mat-card-content>
      <mat-card-footer>
        <div class="center">
          <button class="cancel" mat-button (click)="cancel()">
            {{ "CANCEL" | translate }}
          </button>
          <button *ngIf="!_employee.id" type="button" class="btn add btn-primary" (click)="addEmployeeClicked()">
            {{ "ADD" | translate }}
          </button>
          <button *ngIf="_employee.id" type="button" class="btn update btn-primary" (click)="updateEmployeeClicked()">
            {{ "save" | translate }}
          </button>
        </div>
      </mat-card-footer>
    </mat-card>
  </mat-tab>
  <mat-tab label="{{ 'documents' | translate }}">
    <mat-card>
      <mat-card-content>
        <gib-documents *ngIf="employee" [userId]="employee.id" [fileType]="'TRAINER_PROFILE_DOCUMENT'" (documentEvent)="onDocumentEvent()"></gib-documents>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="{{ 'DRIVING_LICENSE' | translate }}">
    <mat-card>
      <mat-card-content>
        <gib-documents *ngIf="employee" [userId]="employee.id" [fileType]="'DRIVING_LICENSE'" (documentEvent)="onDocumentEvent()"></gib-documents>
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>
