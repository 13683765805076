import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { EventService } from "src/app/services/event.service";
import { GibDialogService } from "src/app/components/dialogs/gib-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidators } from "src/app/utils/custom-validator";

@Component({
  selector: "event-participant",
  templateUrl: "./event-participant.component.html",
  styleUrls: ["./event-participant.component.scss"],
})
export class EventParticipantComponent implements OnInit {
  @Input() eventParticipant: UntypedFormGroup;
  @Input() sendAppointmentlistToCorporateCustomer: boolean = false;
  @Input() eventInquiryHealthscreening: UntypedFormGroup;
  userRole: string = null;
  constructor(private eventService: EventService, private dialogService: GibDialogService, private translateService: TranslateService, private customValidators: CustomValidators) {}

  ngOnInit() {
    this.setRequired();
    this.userRole = localStorage.getItem("role");
  }

  setRequired() {
    this.eventParticipant.get("firstname").setValidators([Validators.required]);
    this.eventParticipant.get("lastname").setValidators([Validators.required]);
    this.eventParticipant.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    if (this.scheduleEntryCompanynameRequired()) {
      this.eventParticipant.get("companyname").setValidators([Validators.required]);
      this.eventParticipant.get("zip").setValidators([Validators.required]);
    } else {
      this.eventParticipant.get("companyname").clearValidators();
      this.eventParticipant.get("zip").clearValidators();
    }
  }

  copyMeeintingLink(eventParticipant: UntypedFormGroup) {
    const meetingUrl = eventParticipant.get("meetingUrl").value;
    const copyButton = document.createElement("textarea");

    copyButton.style.position = "fixed";
    copyButton.style.left = "0";
    copyButton.style.top = "0";
    copyButton.style.opacity = "0";
    copyButton.value = meetingUrl;
    document.body.appendChild(copyButton);
    copyButton.focus();
    copyButton.select();
    document.execCommand("copy");
    document.body.removeChild(copyButton);
  }

  sendReminderMailToOnlineEventParticipant(eventParticipant: UntypedFormGroup) {
    const scheduleEntryId = eventParticipant.get("id").value;
    this.eventService.sendReminderMailToOnlineEventParticipant(scheduleEntryId).subscribe((res) => {
      this.openSendReminderMailToOnlineEventParticipantSuccessDialog();
    });
  }

  sendReminderMailToOnlineEventParticipantClicked(eventParticipant: UntypedFormGroup) {
    const title = this.translateService.instant("sendReminderMailToOnlineEventParticipantTitle");
    const text = this.translateService.instant("sendReminderMailToOnlineEventParticipantText");
    this.dialogService.openConfirmationDialog(title, text, () => this.sendReminderMailToOnlineEventParticipant(eventParticipant));
  }

  openSendReminderMailToOnlineEventParticipantSuccessDialog() {
    const title = this.translateService.instant("sendReminderMailToOnlineEventParticipantSuccessTitle");
    const text = this.translateService.instant("sendReminderMailToOnlineEventParticipantSucessText");
    this.dialogService.openDialog(title, text, "success");
  }

  scheduleEntryCompanynameRequired(): boolean {
    return this.eventInquiryHealthscreening && this.eventInquiryHealthscreening.get("scheduleEntryCompanynameRequired") && this.eventInquiryHealthscreening.get("scheduleEntryCompanynameRequired").value;
  }

  showSendReminder(eventParticipant: UntypedFormGroup) {
    if (eventParticipant.get("email").value !== "" && eventParticipant.get("email").value !== null) {
      return true;
    }
    return false;
  }
}
