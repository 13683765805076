import { ActionReducerMap, createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromRouterStore from "@ngrx/router-store";

import * as fromRouter from "./router.reducer";
import * as fromActiveEvents from "./active-event.reducer";
import * as fromCancelledEvents from "./cancelled-event.reducer";
import * as fromInactiveEvents from "./inactive-event.reducer";

export interface GibState {
  routerReducer: fromRouter.State;
  activeEvents: fromActiveEvents.ActiveEventsState;
  cancelledEvents: fromCancelledEvents.CancelledEventsState;
  inactiveEvents: fromInactiveEvents.InactiveEventsState;
}

export const reducers: ActionReducerMap<GibState> = {
  routerReducer: fromRouterStore.routerReducer,
  activeEvents: fromActiveEvents.reducer,
  cancelledEvents: fromCancelledEvents.reducer,
  inactiveEvents: fromInactiveEvents.reducer,
};

export const getGibState = createFeatureSelector<GibState>("gib");
