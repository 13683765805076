import { MeetingService } from "./../../../../services/meeting.service";
import { Component, OnInit, Inject, ChangeDetectorRef } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { EventService, EventInquiryHealthscreening } from "../../../../services/event.service";
import { FormHelper } from "../../../../helper/form.helper";
import { UntypedFormGroup } from "@angular/forms";
import { InternalNoteService } from "src/app/services/internal-note.service";

@Component({
  selector: "trainer-event-details-sheet",
  templateUrl: "./trainer-event-details-sheet.component.html",
  styleUrls: ["./trainer-event-details-sheet.component.scss"],
})
export class TrainerEventDetailsSheetComponent implements OnInit {
  eventId: number;
  eihsId: number;
  eihsIndex: string;
  eventForm: UntypedFormGroup;
  meetingForm: UntypedFormGroup;
  userRole = "";
  selectedTrainer: string;

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, private eventService: EventService, private formHelper: FormHelper, private cdr: ChangeDetectorRef, private meetingService: MeetingService) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.eventId = this.data.eventId;
    this.eihsId = this.data.eihsId;
    if (this.data && this.data.selectedTrainerUsername) {
      this.selectedTrainer = this.data.selectedTrainerUsername;
    }
    this.eventService.findById(this.data.eventId).subscribe((res) => {
      this.eihsIndex = this.eventService.getEventInquiryHealthscreeningIndex(res.body, this.eihsId);
      this.eventForm = this.eventService.mapEventToForm(res.body);
      this.formHelper.disableControls(this.eventForm);
      this.cdr.detectChanges();
    });
    this.meetingService.getMeetingForEihsId(this.eihsId).subscribe((res) => {
      this.meetingForm = this.meetingService.mapMeetingToForm(res.body);
      this.formHelper.disableControls(this.meetingForm);
      this.cdr.detectChanges();
    });
  }

  showIfAssignedTrainerIsCurrentUser(eihs: UntypedFormGroup) {
    if (eihs && eihs.get("trainer") && eihs.get("trainer").get("0")) {
      return eihs.get("trainer").get("0").get("username").value === this.selectedTrainer;
    }
    return false;
  }
}
