import { OnlineMeetingInformationComponent } from "./components/form-components/online-meeting-information/online-meeting-information.component";
import { GibGridHospitantSelectComponent } from "./../../components/gib-grid-hospitant-select/gib-grid-hospitant-select.component";
import { EventOverviewPageComponent } from "./event-overview-page/event-overview-page.component";
import { EventInquiryPageComponent } from "./event-inquiry-page/event-inquiry-page.component";
import { UtilsModule } from "src/app/utils/utils.module";
import { NgModule } from "@angular/core";
import { ComponentsModule } from "src/app/components/components.module";
import { EventTableComponent } from "./components/event-table/event-table.component";
import { CustomerSubmitPageComponent } from "./customer-submit-page/customer-submit-page.component";
import { EventBookingFormComponent } from "./components/event-booking-form/event-booking-form.component";
import { EventInquiryFormComponent } from "./components/event-inquiry-form/event-inquiry-form.component";
import { EventTasksPageComponent } from "./event-tasks-page/event-tasks-page.component";
import { EventTaskTableComponent } from "./components/event-task-table/event-task-table.component";
import { MyEventsComponent } from "./my-events/my-events.component";
import { ScheduleModelComponent } from "./components/schedule-model/schedule-model.component";
import { EventParticipantComponent } from "./components/event-participant/event-participant.component";
import { EventCompleteFormComponent } from "./components/event-complete-form/event-complete-form.component";
import { EventTimingComponent } from "./components/form-components/event-timing/event-timing.component";
import { EventCustomerDataComponent } from "./components/form-components/event-customer-data/event-customer-data.component";
import { EventLocationComponent } from "./components/form-components/event-location/event-location.component";
import { EventCommentComponent } from "./components/form-components/event-comment/event-comment.component";
import { EventFurtherInformationComponent } from "./components/form-components/event-further-information/event-further-information.component";
import { TaskBottomSheetComponent } from "./components/task-bottom-sheet/task-bottom-sheet.component";
import { TrainerForModuleSelectionComponent } from "./components/trainer-for-module-selection/trainer-for-module-selection.component";
import { TrainerForModuleSelectorComponent } from "./components/trainer-for-module-selector/trainer-for-module-selector.component";
import { EventGridComponent } from "./components/event-grid/event-grid.component";
import { AgGridModule } from "ag-grid-angular";
import { GibGridInputComponent } from "../../components/gib-grid-input/gib-grid-input.component";
import { GibGridTrainerSelectComponent } from "../../components/gib-grid-trainer-select/gib-grid-trainer-select.component";
import { ScheduleVotingPageComponent } from "./schedule-voting-page/schedule-voting-page.component";
import { VotingParticipantComponent } from "./components/voting-participant/voting-participant.component";
import { EventLogisticsFormComponent } from "./components/event-logistics-form/event-logistics-form.component";
import { EventLogisticsBottomSheetComponent } from "./components/event-logistics-bottom-sheet/event-logistics-bottom-sheet.component";
import { GridLogisticButtonComponent } from "./components/grid-logistic-button/grid-logistic-button.component";
import { LogisticTrainerComponent } from "./components/form-components/logistic-trainer/logistic-trainer.component";
import { LogisticVehicleComponent } from "./components/form-components/logistic-vehicle/logistic-vehicle.component";
import { GridEventAbortButtonComponent } from "./components/grid-event-abort-button/grid-event-abort-button.component";
import { CustomerConfirmationPageComponent } from "./customer-confirmation-page/customer-confirmation-page.component";
import { CustomerFeedbackFormComponent } from "./components/customer-feedback-form/customer-feedback-form.component";
import { TrainerFeedbackFormComponent } from "./components/trainer-feedback-form/trainer-feedback-form.component";
import { CustomerFeedbackPageComponent } from "./customer-feedback-page/customer-feedback-page.component";
import { EventDetailsBottomSheetComponent } from "./components/event-details-bottom-sheet/event-details-bottom-sheet.component";
import { GridFeedbackButtonComponent } from "./components/grid-feedback-button/grid-feedback-button.component";
import { TrainerEventDetailsSheetComponent } from "../trainer/components/trainer-event-details-sheet/trainer-event-details-sheet.component";
import { GridEventDetailsButtonComponent } from "./components/grid-event-details-button/grid-event-details-button.component";
import { CompletedTaskTableComponent } from "./components/completed-task-table/completed-task-table.component";
import { EventBookingPageComponent } from "./event-booking-page/event-booking-page.component";
import { FeedbackBottomSheetComponent } from "./components/feedback-bottom-sheet/feedback-bottom-sheet.component";
import { InternalNoteComponent } from "./components/form-components/internal-note/internal-note.component";
import { LockingDialogComponent } from "./components/locking-dialog/locking-dialog.component";
import { EventSignoffComponent } from "./components/form-components/event-signoff/event-signoff.component";
import { EventWaitlistComponent } from "./components/event-waitlist/event-waitlist.component";
import { RouterModule } from "@angular/router";
import { DatePipe } from "@angular/common";

@NgModule({
  declarations: [
    EventInquiryFormComponent,
    EventInquiryPageComponent,
    EventTableComponent,
    EventOverviewPageComponent,
    EventBookingFormComponent,
    EventTasksPageComponent,
    EventTaskTableComponent,
    CustomerSubmitPageComponent,
    MyEventsComponent,
    ScheduleModelComponent,
    EventParticipantComponent,
    EventCompleteFormComponent,
    EventTimingComponent,
    EventCustomerDataComponent,
    EventLocationComponent,
    EventCommentComponent,
    EventFurtherInformationComponent,
    TaskBottomSheetComponent,
    TrainerForModuleSelectionComponent,
    TrainerForModuleSelectorComponent,
    EventGridComponent,
    ScheduleVotingPageComponent,
    VotingParticipantComponent,
    EventLogisticsFormComponent,
    EventLogisticsBottomSheetComponent,
    GridLogisticButtonComponent,
    LogisticTrainerComponent,
    LogisticVehicleComponent,
    GridEventAbortButtonComponent,
    CustomerConfirmationPageComponent,
    TrainerFeedbackFormComponent,
    CustomerFeedbackFormComponent,
    CustomerFeedbackPageComponent,
    EventDetailsBottomSheetComponent,
    GridFeedbackButtonComponent,
    TrainerEventDetailsSheetComponent,
    GridEventDetailsButtonComponent,
    CompletedTaskTableComponent,
    OnlineMeetingInformationComponent,
    EventBookingPageComponent,
    FeedbackBottomSheetComponent,
    InternalNoteComponent,
    LockingDialogComponent,
    EventSignoffComponent,
    EventWaitlistComponent,
  ],
  imports: [ComponentsModule, RouterModule, UtilsModule, AgGridModule.withComponents([GibGridInputComponent, GibGridTrainerSelectComponent, GibGridHospitantSelectComponent])],
  exports: [EventOverviewPageComponent, EventInquiryPageComponent, EventBookingPageComponent, EventTasksPageComponent, CustomerSubmitPageComponent, CustomerFeedbackPageComponent, TrainerFeedbackFormComponent, MyEventsComponent, TrainerEventDetailsSheetComponent],
  providers: [DatePipe],
})
export class EventsModule {}
