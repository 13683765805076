import { FormHelper } from "src/app/helper/form.helper";
import { Component, OnInit, ViewChild } from "@angular/core";
import { Customer, CustomerService } from "./../../../services/customer.service";
import { CustomerTableComponent } from "./../components/customer-table/customer-table.component";

@Component({
  selector: "customer-page",
  templateUrl: "./customer-page.component.html",
  styleUrls: ["./customer-page.component.scss"],
})
export class CustomerPageComponent implements OnInit {
  @ViewChild("custmrTable", { static: true }) customerTable: CustomerTableComponent;

  customers: Customer[];
  selectedCustomer: Customer;
  showAddCustomerCard = false;

  constructor(private customerService: CustomerService, private formHelper: FormHelper) {}

  ngOnInit() {
    this.getUsers();
  }

  getUsers() {
    this.customerService.findAll().subscribe((res) => {
      this.selectedCustomer = null;
      this.customerTable.resetDisplayedColumns();
      this.customers = res.body;
      this.customers = res.body.sort(this.formHelper.sortByLastnameAndFirstname);
    });
  }

  setSelectedCustomer(custmr: Customer) {
    this.selectedCustomer = custmr;
    this.showAddCustomerCard = true;
  }

  openAddCustomerCard() {
    this.selectedCustomer = new Customer();
    this.showAddCustomerCard = true;
  }
}
