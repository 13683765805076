import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges, AfterViewInit, AfterViewChecked, AfterContentInit, AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
  selector: "gib-checkbox",
  templateUrl: "./gib-checkbox.component.html",
  styleUrls: ["./gib-checkbox.component.scss"],
})
export class GibCheckboxComponent implements OnInit {
  inputControl: UntypedFormControl;

  @Input() label: string;
  @Input() disabled: boolean = false;

  @Input() set control(controlObj: UntypedFormControl) {
    this.inputControl = controlObj;
  }

  get control(): UntypedFormControl {
    return this.inputControl;
  }

  constructor(private cdr: ChangeDetectorRef, public sanitizer: DomSanitizer) {}

  ngOnInit() {}
}
