import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ValueLabel } from '../../../../components/value-label';
import { EmployeeService } from '../../../../services/employee.service';
import { EventApplicationService } from '../../../../services/event-application.service';

@Component({
  selector: 'trainer-for-module-selector',
  templateUrl: './trainer-for-module-selector.component.html',
  styleUrls: ['./trainer-for-module-selector.component.scss']
})
export class TrainerForModuleSelectorComponent implements OnInit {

  _disabled: boolean;
  _eventHealthScreening: UntypedFormGroup;

  @Input() set eventHealthScreening(form: UntypedFormGroup) {
    this._eventHealthScreening = form;
    if (this.eventHealthScreening.get('trainer') && this.eventHealthScreening.get('trainer').get('0')) {
      this.selectedTrainerName = this.eventHealthScreening.get('trainer').get('0').get('officialName').value;
    }
  }

  get eventHealthScreening() {
    return this._eventHealthScreening;
  }

  @Input() set disabled(value: boolean) {
    this._disabled = value;
    if (this._disabled) {
      this.selectedTrainer.disable();
      this.selectedAppliedTrainer.disable();
    } else {
      this.selectedTrainer.enable();
      this.selectedAppliedTrainer.enable();
    }
  }

  get disabled(): boolean {
    return this._disabled;
  }

  trainer: ValueLabel[] = [];
  appliedTrainer: ValueLabel[] = [];

  selectedTrainer: UntypedFormControl = new UntypedFormControl();
  selectedAppliedTrainer: UntypedFormControl = new UntypedFormControl();
  selectedTrainerName: string;

  constructor(private employeeService: EmployeeService, private formBuilder: UntypedFormBuilder,
              private eventApplicationService: EventApplicationService) {}

  ngOnInit() {
    this.getTrainerForModule(this._eventHealthScreening.get('healthscreening').get('id').value);
    this.selectedTrainer.valueChanges.subscribe(res => {
      if (res) {
        this.selectedAppliedTrainer.setValue(null);
        this.employeeService.findOne(res).subscribe(trainer => {
          this._eventHealthScreening.removeControl('trainer');
          this._eventHealthScreening.addControl('trainer', this.formBuilder.array([this.employeeService.mapEmployeeToForm(trainer.body)]));
          this.selectedTrainerName = this._eventHealthScreening.get('trainer').get('0').get('officialName').value;
        });
      }
    });
    this.selectedAppliedTrainer.valueChanges.subscribe(res => {
      if (res) {
        this.selectedTrainer.setValue(null);
        this.employeeService.findOne(res).subscribe(trainer => {
          this._eventHealthScreening.removeControl('trainer');
          this._eventHealthScreening.addControl('trainer', this.formBuilder.array([this.formBuilder.group(trainer.body)]));
          this.selectedTrainerName = this._eventHealthScreening.get('trainer').get('0').get('officialName').value;
        });
      }
    });
  }

  getTrainerForModule(id: number) {
    this.employeeService.getTrainerForHealthScreening(id).subscribe(res => {
      for (const emp of res.body) {
        this.trainer.push({ label: emp.officialName, value: emp.id });
      }
    });
    this.eventApplicationService.getAppliedEmployeesForEventHealthScreening(id).subscribe(res => {
      for (const emp of res.body) {
        this.appliedTrainer.push({ label: emp.officialName, value: emp.id });
      }
    });
  }

}
