<div *ngIf="!invalidUrl">
  <h1>{{ "yourBooking" | translate }}</h1>
  <div class="row align-items-center">
    <div class="aa-parent">
      <div></div>
      <div class="col-12" style="max-width: 920px">
        <event-booking-form *ngIf="eventForm" [showPrintButtons]="showPrintButtons" [buid]="buid" [eventForm]="eventForm" [chatElements]="chatElements"></event-booking-form>
      </div>
      <div></div>
    </div>
    <div class="col-sm-3"></div>
    <div class="col-sm-6 button-div">
      <div *ngIf="showSubmitButtons" class="button-div">
        <button *ngIf="showDefaultButton()" class="btn btn-primary" (click)="openSubmitConfirmationClicked()">
          <i class="material-icons">check</i>
          {{ "submitData" | translate }}
        </button>
        <button *ngIf="showBookingButtons()" class="btn btn-success" (click)="openBokkingConfirmationClicked()">
          <i class="material-icons">check</i>
          {{ "book" | translate }}
        </button>
        <button *ngIf="showBookingButtons()" class="btn btn-secondary" (click)="openQueryConfirmation()">
          <i class="material-icons">chat</i>
          {{ "query" | translate }}
        </button>
        <button *ngIf="showBookingButtons()" class="btn btn-danger" (click)="openRejectConfirmation()">
          <i class="material-icons">close</i>
          {{ "storn" | translate }}
        </button>
        <button *ngIf="showOrderConfirmationButton()" class="btn btn-success" (click)="openOrderConfirmationClicked()">
          <i class="material-icons">check</i>
          {{ "orderConfirmation" | translate }}
        </button>
      </div>
      <div [@visibility]="!showSubmitButtons ? 'visible' : 'hidden'">
        <p [ngStyle]="{ color: resultColor }">
          <b>{{ result | translate }}</b>
        </p>
      </div>
    </div>
    <div class="col-sm-3"></div>
  </div>
</div>
