import { MyTasksPageComponent } from "./my-tasks-page/my-tasks-page.component";
import { NgModule } from "@angular/core";
import { ComponentsModule } from "../../components/components.module";
import { UtilsModule } from "../../utils/utils.module";
import { EventApplicationComponent } from "./components/event-application/event-application.component";
import { RunningApplicationComponent } from "./components/running-application/running-application.component";
import { EventApplicationPageComponent } from "./event-application-page/event-application-page.component";
import { MyApplicationPageComponent } from "./my-application-page/my-application-page.component";
import { TrainerTaskTableComponent } from "./components/trainer-task-table/trainer-task-table.component";
import { TrainerCalendarPageComponent } from "./trainer-calendar-page/trainer-calendar-page.component";
import { CalendarModule } from "angular-calendar";
import { TrainerAvailablityFormComponent } from "./components/trainer-availablity-form/trainer-availablity-form.component";
import { TrainerEventDetailsSheetComponent } from "./components/trainer-event-details-sheet/trainer-event-details-sheet.component";
import { TrainerAvailabilityOverviewComponent } from "./trainer-availability-overview/trainer-availability-overview.component";
import { TrainerAvailabilityOverviewGridComponent } from "./components/trainer-availability-overview-grid/trainer-availability-overview-grid.component";
import { TrainerAvailabilityBoxComponent } from "./components/trainer-availability-box/trainer-availability-box.component";
import { AppRoutingModule } from "src/app/app-routing.module";

@NgModule({
  declarations: [
    EventApplicationPageComponent,
    EventApplicationComponent,
    MyApplicationPageComponent,
    RunningApplicationComponent,
    MyTasksPageComponent,
    TrainerTaskTableComponent,
    TrainerCalendarPageComponent,
    TrainerAvailablityFormComponent,
    TrainerAvailabilityOverviewComponent,
    TrainerAvailabilityOverviewGridComponent,
    TrainerAvailabilityBoxComponent,
  ],
  imports: [ComponentsModule, UtilsModule, CalendarModule, AppRoutingModule],
  exports: [EventApplicationPageComponent, MyApplicationPageComponent],
})
export class TrainerModule {}
