<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <gib-h5 [text]="'eventInformation'"></gib-h5>
  </div>
  <div class="col-sm-1"></div>

  <div class="col-sm-1"></div>
  <div class="col-sm-3">
    <gib-input [placeholder]="'orderNr'" [control]="logisticsForm.get('orderNumber')"></gib-input>
  </div>
  <div class="col-sm-8"></div>

  <div class="col-sm-1"></div>
  <div class="col-sm-3">
    <gib-input [placeholder]="'yourModule'" [control]="logisticsForm.get('eventHealthScreenings').get(eventInquiryHealthscreeningIndex).get('name')"></gib-input>
  </div>
  <div class="col-sm-8"></div>
</div>
<hr />
<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <event-timing [eventForm]="logisticsForm"></event-timing>
  </div>
  <div class="col-sm-1"></div>
</div>
<hr />
<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <gib-h5 [text]="'contactPersonByHealthCompany'"></gib-h5>
    <div class="row">
      <div class="col-sm-4">
        <gib-input [placeholder]="'firstname'" [control]="logisticsForm.get('customerContactFirstname')"></gib-input>
      </div>
      <div class="col-sm-4">
        <gib-input [placeholder]="'lastname'" [control]="logisticsForm.get('customerContactLastname')"></gib-input>
      </div>
      <div class="col-sm-4">
        <gib-input id="inputPhone" [placeholder]="'phonenumber'" [control]="logisticsForm.get('customerContactPhone')"></gib-input>
      </div>
    </div>
  </div>
</div>
<hr />
<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <event-location [eventForm]="logisticsForm"></event-location>
  </div>
  <div class="col-sm-1"></div>
</div>
<hr />
<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-4">
    <gib-input [placeholder]="'targetGroup'" [control]="logisticsForm.get('targetGroup')"></gib-input>
  </div>
  <div class="col-sm-7"></div>

  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <gib-textarea [placeholder]="'targetGroupComment'" [control]="logisticsForm.get('targetGroupComment')"></gib-textarea>
  </div>
  <div class="col-sm-1"></div>
</div>
<hr />
<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <gib-h5 [text]="'teamAtLocation'"></gib-h5>
    <div *ngFor="let ehs of logisticsForm.get('eventHealthScreenings')['controls']; let i = index">
      <div *ngIf="i + '' !== eventInquiryHealthscreeningIndex">
        <logistic-trainer *ngFor="let trainer of ehs.get('trainer')['controls']" [trainer]="trainer" [eventHealthscreening]="ehs"></logistic-trainer>
      </div>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>
<hr />
<div class="row" *ngIf="!logisticsForm.get('eventHealthScreenings').get(eventInquiryHealthscreeningIndex).get('onlineModule').value">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <gib-h5 [text]="'kfzInfos'"></gib-h5>
    <div *ngFor="let ehs of logisticsForm.get('eventHealthScreenings')['controls']">
      <logistic-vehicle [eventHealthscreening]="ehs"></logistic-vehicle>
    </div>
  </div>
  <div class="col-sm-1"></div>
</div>
<hr />
<div class="row" *ngIf="logisticsForm.get('eventHealthScreenings').get(eventInquiryHealthscreeningIndex).get('onlineModule').value">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <gib-h5 [text]="'onlineMeetingInfo'"></gib-h5>
    <online-meeting-information *ngIf="meetingForm" [meetingForm]="meetingForm"></online-meeting-information>
  </div>
  <div class="col-sm-1"></div>
</div>
<div class="row">
  <div class="col-sm-1"></div>
  <div class="col-sm-10">
    <event-comment [eventForm]="logisticsForm"></event-comment>
    <gib-textarea [placeholder]="'commentsAdditions'" [control]="logisticsForm.get('commentsAdditions')"></gib-textarea>
    <gib-input [placeholder]="'urlForTrainer'" [control]="logisticsForm.get('eventHealthScreenings').get(eventInquiryHealthscreeningIndex).get('urlForTrainer')"></gib-input>
  </div>
  <div class="col-sm-1"></div>
</div>
