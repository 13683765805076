import { Observable } from "rxjs";
import { HttpConfig } from "./../utils/authentication/auth-interceptor";
import { Injectable } from "@angular/core";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { ScheduleEntry } from "./event.service";
import { EnvService } from "./env.service";

@Injectable()
export class ScheduleService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };

  constructor(private http: HttpClient, private envService: EnvService) {}

  fetchAllScheduleEntriesByUuid(uuid: string, userId: string): Observable<HttpConfig> {
    if (userId) {
      return this.http.get(this.envService.backendUrl + "/public/schedulevote?uuid=" + uuid + "&userId=" + userId, this.config);
    } else {
      return this.http.get(this.envService.backendUrl + "/public/schedulevote?uuid=" + uuid, this.config);
    }
  }

  updateScheduleEntries(scheduleEntry: ScheduleEntry): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/public/schedulevote", scheduleEntry, this.config);
  }

  deleteScheduleEntry(scheduleEntryId: number, userId: string): Observable<HttpConfig> {
    return this.http.delete(this.envService.backendUrl + "/public/schedulevote?entryId=" + scheduleEntryId + "&userId=" + userId, this.config);
  }

  downloadExcel(eihsId: number) {
    return this.http.get(this.envService.backendUrl + "/schedulevote/getScheduleExcelExport?eihsId=" + eihsId, { responseType: "blob" });
  }

  addScheduleEntryBefore(eihsId: number) {
    return this.http.post(this.envService.backendUrl + "/schedulevote/addScheduleEntryBefore", eihsId, this.config);
  }

  addScheduleEntryAfter(eihsId: number) {
    return this.http.post(this.envService.backendUrl + "/schedulevote/addScheduleEntryAfter", eihsId, this.config);
  }

  downloadPdf(scheduleUuid: string) {
    return this.http.get(this.envService.backendUrl + "/public/schedulevote/getSchedulePDFAnonymousExport?scheduleUuid=" + scheduleUuid, {
      responseType: "blob",
    });
  }
}
