import { GibFaqPageComponent } from "./pages/trainer-faq/gib-faq-page/gib-faq-page.component";
import { TrainerFaqPageComponent } from "./pages/trainer-faq/trainer-faq-page/trainer-faq-page.component";
import { ReportsPageComponent } from "./pages/administration/reports-page/reports-page.component";
import { ReportsOverviewPageComponent } from "./pages/administration/reports-overview-page/reports-overview-page.component";
import { EventBookingPageComponent } from "./pages/events/event-booking-page/event-booking-page.component";
import { PrivacyPageComponent } from "./pages/privacy-page/privacy-page.component";
import { MailLogPageComponent } from "./pages/administration/mail-log-page/mail-log-page.component";
import { MetadataPageComponent } from "./pages/administration/metadata-page/metadata-page.component";
import { CustomerFeedbackPageComponent } from "./pages/events/customer-feedback-page/customer-feedback-page.component";
import { InfoHealthScreeningPageComponent } from "./pages/health-screening/info-health-screening-page/info-health-screening-page.component";
import { CustomerHealthScreeningOverviewPageComponent } from "./pages/health-screening/customer-health-screening-overview-page/customer-health-screening-overview-page.component";
import { CustomerConfirmationPageComponent } from "./pages/events/customer-confirmation-page/customer-confirmation-page.component";
import { ScheduleVotingPageComponent } from "./pages/events/schedule-voting-page/schedule-voting-page.component";
import { CustomerPageComponent } from "./pages/customer/customer-page/customer-page.component";
import { EventInquiryPageComponent } from "./pages/events/event-inquiry-page/event-inquiry-page.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomePageComponent } from "./pages/home-page/home-page.component";
import { EventOverviewPageComponent } from "./pages/events/event-overview-page/event-overview-page.component";
import { SandboxPageComponent } from "./pages/sandbox-page/sandbox-page.component";
import { EmployeesPageComponent } from "./pages/employees/employees-page/employees-page.component";
import { CustomerRegistrationPageComponent } from "./pages/customer/customer-registration-page/customer-registration-page.component";
import { CustomerRegistrationSubmitPageComponent } from "./pages/customer/customer-registration-submit-page/customer-registration-submit-page.component";
import { AppAuthGuard } from "./utils/authentication/app-auth-guard";
import { ProfilePageComponent } from "./pages/profile-page/profile-page.component";
import { DocumentVerificationOverviewComponent } from "./pages/administration/documents/document-verification-overview/document-verification-overview.component";
import { HealthScreeningOverviewPageComponent } from "./pages/health-screening/health-screening-overview-page/health-screening-overview-page.component";
import { CreateHealthScreeningPageComponent } from "./pages/health-screening/create-health-screening-page/create-health-screening-page.component";
import { EditHealthScreeningPageComponent } from "./pages/health-screening/edit-health-screening-page/edit-health-screening-page.component";
import { TrainerPageComponent } from "./pages/employees/trainer-page/trainer-page.component";
import { CustomerSubmitPageComponent } from "./pages/events/customer-submit-page/customer-submit-page.component";
import { EventTasksPageComponent } from "./pages/events/event-tasks-page/event-tasks-page.component";
import { MyEventsComponent } from "./pages/events/my-events/my-events.component";
import { EventApplicationPageComponent } from "./pages/trainer/event-application-page/event-application-page.component";
import { MyApplicationPageComponent } from "./pages/trainer/my-application-page/my-application-page.component";
import { MyTasksPageComponent } from "./pages/trainer/my-tasks-page/my-tasks-page.component";
import { TrainerCalendarPageComponent } from "./pages/trainer/trainer-calendar-page/trainer-calendar-page.component";
import { ConfigurationPageComponent } from "./pages/administration/configuration-page/configuration-page.component";
import { TrainerAvailabilityOverviewComponent } from "./pages/trainer/trainer-availability-overview/trainer-availability-overview.component";
import { LoginPageComponent } from "./pages/login-page/login-page.component";
import { MeetingAdministrationPageComponent } from "./pages/administration/meeting-administration-page/meeting-administration-page.component";
import { NotFoundPageComponent } from "./pages/not-found-page/not-found-page.component";
import { HealthinsurancePageComponent } from "./pages/customer/healthinsurance-page/healthinsurance-page.component";
import { StatisticsPageComponent } from "./pages/statistics-page/statistics-page.component";

// guards
import * as fromGuards from "./guards";

const routes: Routes = [
  { path: "", component: HomePageComponent, canActivate: [fromGuards.EventsGuard] },
  { path: "event-overview", component: EventOverviewPageComponent },
  {
    path: "employees",
    component: EmployeesPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "trainer",
    component: TrainerPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "sandbox",
    component: SandboxPageComponent,
    canActivate: [fromGuards.EventsGuard],
  },
  {
    path: "customer-registration/submit",
    component: CustomerRegistrationSubmitPageComponent,
    canActivate: [fromGuards.EventsGuard],
  },
  {
    path: "customer-registration",
    component: CustomerRegistrationPageComponent,
    canActivate: [fromGuards.EventsGuard],
  },
  {
    path: "customers",
    component: CustomerPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "my-profile",
    component: ProfilePageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "document-verification-overview",
    component: DocumentVerificationOverviewComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "create-health-screening-page",
    component: CreateHealthScreeningPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "create-health-screening-page/:companynameAlias",
    component: CreateHealthScreeningPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "health-screening-overview-page/:companynameAlias",
    component: HealthScreeningOverviewPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "edit-health-screening-page/:id",
    component: EditHealthScreeningPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "event-inquiry-page/:channel",
    component: EventInquiryPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "event-inquiry-page/:channel/:hsId/:type",
    component: EventInquiryPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "event-overview-page",
    component: EventOverviewPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "event-inquiry/customer-response/submit",
    component: CustomerSubmitPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "event-tasks/:taskIdFilter",
    component: EventTasksPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "my-events",
    component: MyEventsComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "my-events/:buid",
    component: MyEventsComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "event-application",
    component: EventApplicationPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "my-applications",
    component: MyApplicationPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "my-tasks",
    component: MyTasksPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "schedule-voting",
    component: ScheduleVotingPageComponent,
    canActivate: [fromGuards.EventsGuard],
  },
  {
    path: "customer-confirmation-page",
    component: CustomerConfirmationPageComponent,
    canActivate: [fromGuards.EventsGuard],
  },
  {
    path: "trainer-calendar",
    component: TrainerCalendarPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "customer-health-screening-overview-page/:type",
    component: CustomerHealthScreeningOverviewPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "info-health-screening-page/:id/:type",
    component: InfoHealthScreeningPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "customer-feedback-page",
    component: CustomerFeedbackPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "configuration-page",
    component: ConfigurationPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "trainer-availability-overview",
    component: TrainerAvailabilityOverviewComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "metadata",
    component: MetadataPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "login",
    component: LoginPageComponent,
    canActivate: [fromGuards.EventsGuard],
  },
  {
    path: "meeting-administration",
    component: MeetingAdministrationPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "mail-log",
    component: MailLogPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "privacy-page",
    component: PrivacyPageComponent,
    canActivate: [fromGuards.EventsGuard],
  },
  {
    path: "event-booking-page",
    component: EventBookingPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "reports-overview-page",
    component: ReportsOverviewPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "reports-page/:type",
    component: ReportsPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "faq",
    component: TrainerFaqPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "gib-faq",
    component: GibFaqPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "health-insurance",
    component: HealthinsurancePageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  {
    path: "statistics",
    component: StatisticsPageComponent,
    canActivate: [AppAuthGuard, fromGuards.EventsGuard],
  },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  providers: [...fromGuards.guards],
  exports: [RouterModule],
})
export class AppRoutingModule {}
