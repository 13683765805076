import { Injectable } from "@angular/core";
import { HealthScreening } from "./health-screening.service";
import { Subject } from "rxjs";

@Injectable()
export class CartService {
  cartModules: ModuleSelection[] = [];

  constructor() {}

  getCart() {
    return this.cartModules;
  }

  clearCart() {
    this.cartModules = [];
  }

  addToCart(hs: HealthScreening, online: boolean, topic?: string) {
    this.cartModules.push(new ModuleSelection(hs, online, topic));
  }

  hasItems() {
    return this.cartModules.length !== 0;
  }
}

export class ModuleSelection {
  hs: HealthScreening;
  online: boolean;
  topic: string;

  constructor(hs: HealthScreening, online: boolean, topic: string) {
    this.hs = hs;
    this.hs.visibileForCompanynameAliases = [];
    this.online = online;
    this.topic = topic;
    if (!hs.moduleType) {
      hs.moduleType = "HEALTH_MODULE";
    }
  }
}
