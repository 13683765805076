import { animate, state, style, transition, trigger } from "@angular/animations";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidators } from "src/app/utils/custom-validator";
import { GibDialogService } from "../../../components/dialogs/gib-dialog.service";
import { FormHelper } from "../../../helper/form.helper";
import { Chat, Event, EventService } from "../../../services/event.service";
import { TaskOutcome, ProcessService } from "../../../services/process-service";
import { TokenHelperService } from "src/app/services/token-helper.service";

@Component({
  selector: "customer-submit-page",
  templateUrl: "./customer-submit-page.component.html",
  styleUrls: ["./customer-submit-page.component.scss"],
  animations: [trigger("visibility", [state("visible", style({ opacity: 1, height: "*" })), state("hidden", style({ opacity: 0, height: "0px" })), transition("visible <=> hidden", animate(500))])],
})
export class CustomerSubmitPageComponent implements OnInit {
  eventForm: UntypedFormGroup;
  chatElements: Chat[];
  showSubmitButtons = true;
  showPrintButtons: boolean = false;
  result: string;
  resultColor = "red";

  buid: string;
  activityId: string;
  messageName: string;
  invalidUrl = true;

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private dialogService: GibDialogService,
    private formHelper: FormHelper,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private processService: ProcessService,
    private customValidators: CustomValidators,
    private tokenHelperService: TokenHelperService
  ) {}

  ngOnInit() {
    this.loadEventData();
    // this.openSubmitConfirmationClicked();
  }

  handleCorporateCustomerEmailValidation(eventForm: UntypedFormGroup) {
    eventForm.get("sendAppointmentlistToCorporateCustomer").valueChanges.subscribe((sendEmail) => {
      if (sendEmail) {
        eventForm.get("corporateCustomerContactMail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
      } else {
        eventForm.get("corporateCustomerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
      }
      eventForm.get("corporateCustomerContactMail").updateValueAndValidity();
      this.cdr.detectChanges();
    });
  }

  loadEventData() {
    this.route.queryParams.subscribe((values) => {
      this.buid = values["buid"];
      this.activityId = values["activity-id"];
      if (this.processService.isCustomerResponseStep(this.activityId)) {
        this.loadEvent();
        this.setMessageName(this.activityId);
      } else {
        this.dialogService.openErrorDialog("invalidLink", []);
      }
    });
  }

  loadEvent() {
    const role = localStorage.role;
    let gibEmployee = false;
    if (role !== null && (role === "gib_director" || role === "gib_admin" || role === "gib_employee")) {
      gibEmployee = true;
    }
    this.eventService.findByBuid(this.buid).subscribe((res) => {
      const event: Event = res.body;
      if (event.customer.id !== this.tokenHelperService.getUserId() && !gibEmployee) {
        this.cdr.detectChanges();
        return;
      }
      this.cdr.detectChanges();
      this.eventForm = this.eventService.mapEventToForm(event);
      this.createFormValidations(this.eventForm);

      this.handleCorporateCustomerEmailValidation(this.eventForm);

      this.eventForm.addControl("withoutCustomerSuggestSelect", new UntypedFormControl(true));
      this.eventForm.get("eventDate").disable();
      if (this.eventForm.get("startTime").value) {
        this.eventForm.get("startTime").disable();
      }
      if (this.eventForm.get("endTime").value) {
        this.eventForm.get("endTime").disable();
      }
      this.eventForm.get("eventHealthScreenings").disable();
      this.eventForm.get("eventlocation").get("city").disable();
      if (this.eventForm.get("customer").get("id").value) {
        this.eventForm.get("customer").get("id").disable();
        this.formHelper.disableControlsByName(this.eventForm, ["customerSalutation", "customerFirstname", "customerLastname", "customerPhone", "customerMail", "customerCompanyName", "customerRegion", "customerTeamnumber"]);
        this.formHelper.disableControlsByName(this.eventForm.get("customerAddress"), ["street", "number", "city", "zip"]);
      }
      this.eventForm.updateValueAndValidity();
      this.loadChat();
      if (this.showOrderConfirmationButton()) {
        this.formHelper.disableControls(this.eventForm);
      }
      this.invalidUrl = false;
      this.formHelper.disableControlsByName(this.eventForm, ["bookingCopyRecepientMail"]);
      this.markRequiredFieldsAsTouched();
    });
  }

  loadChat() {
    this.eventService.getChatHistory(this.buid, "Antwort_Buchungsformular").subscribe((res) => {
      this.chatElements = res.body;
      this.cdr.detectChanges();
    });
  }

  markRequiredFieldsAsTouched() {
    this.eventForm.get("startTime").markAsTouched();
    this.eventForm.get("endTime").markAsTouched();
    this.eventForm.get("customerCompanyName").markAsTouched();
    this.eventForm.get("corporateCustomerCompanyName").markAsTouched();
    this.eventForm.get("eventlocation").get("street").markAsTouched();
    this.eventForm.get("eventlocation").get("number").markAsTouched();
    this.eventForm.get("eventlocation").get("zip").markAsTouched();
    this.eventForm.get("corporateCustomerContactSalutation").markAsTouched();
    this.eventForm.get("corporateCustomerContactLastname").markAsTouched();
    this.eventForm.get("corporateCustomerContactPhone").markAsTouched();
  }

  openSubmitConfirmationClicked() {
    this.formHelper.isFormValidElseShowErrors(this.eventForm, "customerSubmitFormInvalidTitle", () => this.openSubmitConfirmation());
  }

  openSubmitConfirmation() {
    const title = this.translateService.instant("submitEventDataTitle");
    const text = this.translateService.instant("submitEventDataText");
    this.dialogService.openConfirmationDialog(title, text, () => this.customerResponse("APPROVED", "eventDataSubmittedByCustomer", "var(--success)", null));
  }

  openBokkingConfirmationClicked() {
    this.formHelper.isFormValidElseShowErrors(this.eventForm, "customerSubmitFormInvalidTitle", () => this.openBookingConfirmation());
  }

  openBookingConfirmation() {
    const title = this.translateService.instant("submitEventTitle");
    const text = this.translateService.instant("submitEventText");
    this.dialogService.openConfirmationDialog(title, text, () => this.customerResponse("APPROVED", "eventSubmittedByCustomer", "var(--success)", null));
  }

  openQueryConfirmation() {
    const title = this.translateService.instant("queryTitle");
    const text = this.translateService.instant("queryText");
    const confirmationPlaceholderText = "query";
    this.dialogService.openConfirmationTextDialog(title, text, confirmationPlaceholderText).subscribe((res) => {
      if (res) {
        this.customerResponse("QUERY", "eventQueriedByCustomer", "#000", res);
      }
    });
  }

  openRejectConfirmation() {
    const title = this.translateService.instant("rejectEventInquiryTitle");
    const text = this.translateService.instant("rejectEventInquiryText");
    this.dialogService.openConfirmationDialog(title, text, () => this.customerResponse("REJECTED", "eventRejectedByCustomer", "#000", null));
  }

  openOrderConfirmationClicked() {
    const title = this.translateService.instant("openOrderConfirmationTitle");
    const text = this.translateService.instant("openOrderConfirmationText");
    this.dialogService.openConfirmationDialog(title, text, () => this.customerResponse("APPROVED", "openOrderConfirmationClicked", "#000", null));
  }

  customerResponse(outcome: TaskOutcome, result: string, resultColor: string, query: string) {
    const event = this.eventService.mapFormToEvent(this.eventForm);
    this.eventService.customerResponse(this.buid, outcome, query, event, this.messageName, this.activityId).subscribe(() => {
      this.formHelper.disableControls(this.eventForm);

      if (outcome === "QUERY") {
        this.loadChat();
      }
      this.showSubmitButtons = false;
      this.showPrintButtons = true;
      this.result = result;
      this.resultColor = resultColor;
    });
  }

  createFormValidations(eventForm: UntypedFormGroup) {
    eventForm.get("eventDate").setValidators([Validators.required, this.formHelper.isDateValid()]);
    eventForm.get("startTime").setValidators([Validators.required]);
    eventForm.get("endTime").setValidators([Validators.required]);
    eventForm.get("eventHealthScreenings").setValidators([Validators.required]);
    eventForm.get("eventHealthScreenings").updateValueAndValidity();
    eventForm.get("customerSalutation").setValidators([Validators.required]);
    eventForm.get("customerFirstname").setValidators([Validators.required]);
    eventForm.get("customerLastname").setValidators([Validators.required]);
    eventForm.get("customerPhone").setValidators([Validators.required]);
    eventForm.get("customerMail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("customerCompanyName").setValidators([Validators.required]);

    eventForm.get("corporateCustomerCompanyName").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactSalutation").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactLastname").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactPhone").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);

    eventForm.get("customerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("eventlocation").get("type").setValidators([Validators.required]);
    eventForm.get("eventlocation").get("city").setValidators([Validators.required]);

    if (eventForm.get("sendAppointmentlistToCorporateCustomer").value) {
      eventForm.get("corporateCustomerContactMail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    } else {
      eventForm.get("corporateCustomerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    }

    if (this.activityId === "st_mail_update_data_ag") {
      eventForm.get("eventlocation").get("street").setValidators([Validators.required]);
      eventForm.get("eventlocation").get("number").setValidators([Validators.required]);
      eventForm.get("eventlocation").get("zip").setValidators([Validators.required]);
    }
    if (this.activityId === "st_mail_confirmation_reservation_ag") {
      eventForm.get("expectedAttendees").setValidators(this.formHelper.isValidNumber());
    }
  }

  setMessageName(activityId: string) {
    if (activityId === "st_mail_confirmation_reservation_ag" || activityId === "st_email_reminder" || activityId === "st_mail_query_processed_ag") {
      this.messageName = "msg_response_ag";
    } else if (activityId === "st_mail_update_data_ag" || activityId === "st_mail_update_data_reminder_ag") {
      this.messageName = "msg_update_data_ag";
    } else if (activityId === "st_send_order_confirmation_ag" || activityId === "st_send_order_confirmation_reminder_ag") {
      this.messageName = "msg_confirmation";
    }
  }

  showBookingButtons() {
    return this.activityId === "st_mail_confirmation_reservation_ag" || this.activityId === "st_email_reminder" || this.activityId === "st_mail_query_processed_ag";
  }

  showOrderConfirmationButton() {
    return this.activityId === "st_send_order_confirmation_ag";
  }

  showDefaultButton() {
    return this.activityId === "st_mail_update_data_ag" || this.activityId === "st_mail_update_data_reminder_ag";
  }
}
