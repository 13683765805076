import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormHelper } from '../../../../helper/form.helper';
import { FileService } from './../../../../services/file.service';

@Component({
  selector: 'employee-profile',
  templateUrl: './employee-profile.component.html',
  styleUrls: ['./employee-profile.component.scss']
})
export class EmployeeProfileComponent implements OnInit {
  approvedPic: boolean;
  downloadUrl: string;

  @Input() editMode: boolean;
  @Output() editModeChange: EventEmitter < boolean > = new EventEmitter < boolean > ();

  @Input() formGroup: UntypedFormGroup;

  constructor(private formHelper: FormHelper, private fileService: FileService) {}

  ngOnInit() {
    this.checkForUploadedDrivingLicense();
  }

  checkForUploadedDrivingLicense() {
    this.fileService.getFileMetaByUserAndType(this.formGroup.get('id').value, 'DRIVING_LICENSE').subscribe(res => {
      this.formGroup.get('carDrivingLicenseCopyFile').setValue(res);
      if (res) {
        this.formGroup.get('carDrivingLicenseCopy').setValue(true);
        this.formGroup.get('carDrivingLicenseCopy').disable();
      }
    });
  }

  startEditMode() {
    this.editMode = true;
    this.editModeChange.emit(true);
    this.formHelper.enableControls(this.formGroup);
    if (this.formGroup.get('carDrivingLicenseCopyFile') && this.formGroup.get('carDrivingLicenseCopyFile').value.id) {
      this.formGroup.get('carDrivingLicenseCopy').disable();
    }
  }

  showFileUpload() {
    return this.editMode &&
      this.formGroup.get('carDrivingLicenseCopy').value &&
      (!this.formGroup.get('carDrivingLicenseCopyFile').value || (this.formGroup.get('carDrivingLicenseCopyFile').value &&
        !this.formGroup.get('carDrivingLicenseCopyFile').value.id) || (this.formGroup.get('carDrivingLicenseCopyFile').value &&
        (this.formGroup.get('carDrivingLicenseCopyFile').value.status === 'REJECTED' || this.formGroup.get('carDrivingLicenseCopyFile').value.status === 'EXPIRED')));
  }

  showStatus() {
    return this.formGroup.get('carDrivingLicenseCopy').value &&
      this.formGroup.get('carDrivingLicenseCopyFile').value &&
      this.formGroup.get('carDrivingLicenseCopyFile').value.id;
  }

  showWarningIcon() {
    return this.formGroup.get('carDrivingLicenseCopy').value &&
      !this.formGroup.get('carDrivingLicenseCopyFile').value;
  }

  downloadFile() {
    this.fileService.getFileData(this.formGroup.get('carDrivingLicenseCopyFile').value.id).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/octet-stream' });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = this.downloadUrl;
      a.download = this.formGroup.get('carDrivingLicenseCopyFile').value.fileName;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

}
