import { GibDialogService } from "./../../../components/dialogs/gib-dialog.service";
import { FormHelper } from "./../../../helper/form.helper";
import { EventService } from "./../../../services/event.service";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { Component, OnInit, ChangeDetectorRef, AfterContentChecked, ViewChild } from "@angular/core";
import { Event } from "src/app/services/event.service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { InternalNoteService, InternalNote } from "src/app/services/internal-note.service";
import { BookAgainService } from "src/app/services/bookAgainService";
import { CustomValidators } from "src/app/utils/custom-validator";
import { EventBookingFormComponent } from "../components/event-booking-form/event-booking-form.component";
import { moduleItemValidator } from "src/app/validators/module-item.validator";

@Component({
  selector: "event-booking-page",
  templateUrl: "./event-booking-page.component.html",
  styleUrls: ["./event-booking-page.component.scss"],
})
export class EventBookingPageComponent implements OnInit, AfterContentChecked {
  eventForm: UntypedFormGroup = this.initFormGroup(new Event());
  internalNoteForm: UntypedFormGroup = this.initInternalNoteForm(new InternalNote());
  formSubmitted: boolean;
  userRole: string;
  showPrintButtons: boolean = false;
  event: Event;

  channel: string;

  buid: string;
  filename;
  downloadUrl;

  @ViewChild("eventBookingForm") eventBookingForm: EventBookingFormComponent;
  private _valid: boolean = true;

  constructor(private eventService: EventService, private formHelper: FormHelper, private translateService: TranslateService, private dialogService: GibDialogService, private internalNoteService: InternalNoteService, private bookAgainService: BookAgainService, private customValidators: CustomValidators) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    if (this.bookAgainService.eventForm) {
      this.loadEventData();
    }
    this.eventForm.get("channel").setValue("BOOKING");
    this.eventForm.get("eventDate").valueChanges.subscribe((res) => {
      const diffTillEvent = this.formHelper.calculateDateDiff(res);
      if (diffTillEvent <= 5 && !this.hasOnlyOnlineModules(this.eventService.mapFormToEvent(this.eventForm).eventHealthScreenings)) {
        this.eventForm.get("eventlocation").get("street").setValidators([Validators.required]);
        this.eventForm.get("eventlocation").get("number").setValidators([Validators.required]);
        this.eventForm.get("eventlocation").get("zip").setValidators([Validators.required]);
      } else {
        this.eventForm.get("eventlocation").get("street").setValidators([]);
        this.eventForm.get("eventlocation").get("number").setValidators([]);
        this.eventForm.get("eventlocation").get("zip").setValidators([]);
      }
      this.eventForm.get("eventlocation").get("street").updateValueAndValidity();
      this.eventForm.get("eventlocation").get("number").updateValueAndValidity();
      this.eventForm.get("eventlocation").get("zip").updateValueAndValidity();
    });
    this.eventForm.get("eventHealthScreenings").valueChanges.subscribe((res) => {
      if (this.eventForm.get("eventDate").value) {
        const diffTillEvent = this.formHelper.calculateDateDiff(this.eventForm.get("eventDate").value);
        if (diffTillEvent <= 5 && !this.hasOnlyOnlineModules(res)) {
          this.eventForm.get("eventlocation").get("street").setValidators([Validators.required]);
          this.eventForm.get("eventlocation").get("number").setValidators([Validators.required]);
          this.eventForm.get("eventlocation").get("zip").setValidators([Validators.required]);
        } else {
          this.eventForm.get("eventlocation").get("street").setValidators([]);
          this.eventForm.get("eventlocation").get("number").setValidators([]);
          this.eventForm.get("eventlocation").get("zip").setValidators([]);
        }
        this.eventForm.get("eventlocation").get("street").updateValueAndValidity();
        this.eventForm.get("eventlocation").get("number").updateValueAndValidity();
        this.eventForm.get("eventlocation").get("zip").updateValueAndValidity();
      }
    });
  }

  ngAfterContentChecked(): void {
    if (this.eventBookingForm) {
      this._valid = this.eventBookingForm.itemsValid;
      this.eventForm.setValidators(moduleItemValidator(this._valid));
      this.eventForm.updateValueAndValidity();
    }
  }

  loadEventData() {
    this.eventForm = this.bookAgainService.eventForm;
    this.bookAgainService.clearEventForm();
  }

  hasOnlyOnlineModules(modules: any[]) {
    for (const module of modules) {
      if (!module.onlineModule) {
        return false;
      }
    }
    return true;
  }

  initInternalNoteForm(internalNote: InternalNote): UntypedFormGroup {
    const internalNoteForm = this.internalNoteService.mapInternalNoteToForm(internalNote);
    return internalNoteForm;
  }

  initFormGroup(event: Event): UntypedFormGroup {
    const eventForm = this.eventService.mapEventToForm(event);
    eventForm.get("eventDate").setValidators([Validators.required, this.formHelper.isDateValid()]);
    eventForm.get("startTime").setValidators([Validators.required]);
    eventForm.get("endTime").setValidators([Validators.required]);
    eventForm.get("eventHealthScreenings").setValidators([Validators.required]);
    eventForm.get("eventHealthScreenings").updateValueAndValidity();
    eventForm.get("customerSalutation").setValidators([Validators.required]);
    eventForm.get("customerFirstname").setValidators([Validators.required]);
    eventForm.get("customerLastname").setValidators([Validators.required]);
    eventForm.get("customerPhone").setValidators([Validators.required]);
    eventForm.get("customerMail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("customerCompanyName").setValidators([Validators.required]);

    eventForm.get("corporateCustomerCompanyName").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactSalutation").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactLastname").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactPhone").setValidators([Validators.required]);
    eventForm.get("corporateCustomerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);

    eventForm.get("customerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("eventlocation").get("type").setValidators([Validators.required]);
    eventForm.get("eventlocation").get("city").setValidators([Validators.required]);

    if (eventForm.get("sendAppointmentlistToCorporateCustomer").value) {
      eventForm.get("corporateCustomerContactMail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    } else {
      eventForm.get("corporateCustomerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    }
    eventForm.get("bookingCopyRecepientMailTemp").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);

    eventForm.get("expectedAttendees").setValidators(this.formHelper.isValidNumber());
    return eventForm;
  }

  disableCustomerData() {
    this.formHelper.disableControlsByName(this.eventForm, ["customerSalutation", "customerFirstname", "customerLastname", "customerPhone", "customerMail", "customerCompanyName", "customerRegion", "customerTeamnumber"]);
    this.formHelper.disableControlsByName(this.eventForm.get("customerAddress"), ["street", "number", "city", "zip"]);
  }

  startProcessClicked() {
    const today = new Date();
    if (this.userRole === "gib_customer") {
      if (this.eventForm.get("eventDate") && moment(this.eventForm.get("eventDate").value).isAfter(today, "day")) {
        this.formHelper.isFormValidElseShowErrors(this.eventForm, "eventInquireStartFailedTitle", () => this.startProcess());
      } else {
        const title = this.translateService.instant("dateInPastTitle");
        const text = this.translateService.instant("dateInPastText");
        this.dialogService.openDialog(title, text, "success");
      }
    } else {
      this.formHelper.isFormValidElseShowErrors(this.eventForm, "eventInquireStartFailedTitle", () => this.startProcess());
    }
  }

  startProcess() {
    const event = this.eventService.mapFormToEvent(this.eventForm);
    const internalNote = this.internalNoteService.mapFormToInternalNote(this.internalNoteForm);
    this.eventService.startProcess(event).subscribe((res) => {
      this.buid = res.body.buid;
      this.formHelper.disableControls(this.eventForm);
      // disable separately again to throw the valuechange event here,
      // so the module selection component can handle the listener and disable the controls into the component itself
      this.eventForm.get("eventHealthScreenings").disable();
      this.formSubmitted = true;
      this.showPrintButtons = true;
      this.openSuccessfullyCreatedDialog();
      internalNote.id = res.body.daoID;
      this.internalNoteService.update(internalNote, this.userRole).subscribe((res) => {
        this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(res.body);
        this.formHelper.disableControls(this.internalNoteForm);
      });
    });
  }

  openSuccessfullyCreatedDialog() {
    const title = this.translateService.instant("bookingStartedTitle");
    const text = this.translateService.instant("bookingStartedText");
    this.dialogService.openDialog(title, text, "success");
  }

  bookEventAgain() {
    this.formSubmitted = false;
    this.showPrintButtons = false;
    this.eventService.copyEventForm(this.eventForm);
    this.formHelper.enableControls(this.eventForm);
    this.formHelper.enableControls(this.internalNoteForm);
    this.eventForm.get("eventHealthScreenings").enable();
    this.eventService.disableCustomerData(this.eventForm);
  }

  openDownloadPdfConfirmation() {
    const title = this.translateService.instant("downloadPdfTitle");
    const text = this.translateService.instant("downloadPdfText");
    if (this.translateService.currentLang === "en") {
      this.filename = "eventinformation.pdf";
    } else if (this.translateService.currentLang === "de") {
      this.filename = "veranstaltungsinformation.pdf";
    }
    this.dialogService.openConfirmationDialog(title, text, () => this.printFormToPdf(this.filename));
  }

  printFormToPdf(filename: string) {
    this.eventService.getSignOffPdfByBuid(this.buid).subscribe((res) => {
      const blob = new Blob([res], { type: " application/pdf" });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = filename;
      a.href = this.downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }
}
