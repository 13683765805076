import { Component, OnInit } from '@angular/core';
import { EventApplicationService } from '../../../services/event-application.service';
import { EventInquiryHealthscreening } from '../../../services/event.service';

@Component({
  selector: 'my-application-page',
  templateUrl: './my-application-page.component.html',
  styleUrls: ['./my-application-page.component.sass']
})
export class MyApplicationPageComponent implements OnInit {

  myApplications: EventInquiryHealthscreening[];

  constructor(private eventApplicationsService: EventApplicationService) { }

  ngOnInit() {
    this.eventApplicationsService.getMyApplications().subscribe(res => {
      this.myApplications = res.body;
    });
  }

}
