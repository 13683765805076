import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { ValueLabel } from "src/app/components/value-label";

@Component({
  selector: "event-further-information",
  templateUrl: "./event-further-information.component.html",
  styleUrls: ["./event-further-information.component.scss"],
})
export class EventFurtherInformationComponent implements OnInit, OnChanges, OnDestroy {
  @Input() eventForm: UntypedFormGroup;

  userRole: string;

  plannedLocationOptions: ValueLabel[] = [
    { value: "indoor", label: this.translateService.instant("indoor") },
    { value: "outdoor", label: this.translateService.instant("outdoor") },
  ];

  parkingPossibleOptions: ValueLabel[] = [
    { value: "true", label: this.translateService.instant("yes") },
    { value: "false", label: this.translateService.instant("alternative") },
  ];

  targetGroups = this.fb.group({
    officeStaff: [false],
    physicalWorkers: [false],
    apprentices: [false],
    students: [false],
    other: [false],
    otherText: [""],
  });

  subscription: Subscription;

  constructor(private translateService: TranslateService, private fb: FormBuilder) {}

  ngOnInit() {
    this.mapData();

    this.userRole = localStorage.getItem("role");
    this.subscription = this.targetGroups.valueChanges.subscribe((changes) => {
      const targetGroups: string[] = [];
      Object.keys(changes).forEach((key) => {
        if (changes[key] === true) {
          if (key === "other") {
            if (changes["otherText"]) {
              targetGroups.push(key + "|:|" + changes["otherText"]);
            } else {
              targetGroups.push(key);
            }
          } else {
            targetGroups.push(key);
          }
        }
      });
      this.eventForm.get("targetGroup").setValue(targetGroups.join("|,|"));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.eventForm) {
      const { currentValue, previousValue } = changes.eventForm;
      if (previousValue) {
        this.mapData();
      }
    }
  }

  private mapData() {
    const targetGroup: string = this.eventForm.get("targetGroup").value;
    if (targetGroup) {
      const targetGroupArray = targetGroup.split("|,|");
      for (const targetGroupItem of targetGroupArray) {
        if (targetGroupItem.startsWith("other")) {
          const otherArray = targetGroupItem.split("|:|");
          this.targetGroups.get(otherArray[0]).setValue(true);
          if (otherArray.length > 1) {
            this.targetGroups.get("otherText").setValue(otherArray[1]);
          }
        } else {
          this.targetGroups.get(targetGroupItem).setValue(true);
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
