import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GibDialogService } from '../../../../components/dialogs/gib-dialog.service';
import { EventApplicationService } from '../../../../services/event-application.service';
import { EventInquiryHealthscreening } from '../../../../services/event.service';

@Component({
  selector: 'event-application',
  templateUrl: './event-application.component.html',
  styleUrls: ['./event-application.component.sass'],
  animations: [
    trigger('visibility', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0px' })),
      transition('visible <=> hidden', animate(500)),
    ]),
  ]
})
export class EventApplicationComponent implements OnInit {

  @Input() eventInquiryHealthScreening: EventInquiryHealthscreening;

  showButtons = true;

  constructor(private dialogService: GibDialogService, private translateService: TranslateService,
              private eventApplcationService: EventApplicationService) {}

  ngOnInit() {}

  createAddressString(): string {
    let addressString = '';
    if (this.eventInquiryHealthScreening.eventInquiry.eventlocation) {
      const location = this.eventInquiryHealthScreening.eventInquiry.eventlocation;
      addressString += (location.street ? location.street : '');
      addressString += ' ';
      addressString += (location.number ? location.number : '');
      addressString += ', ';
      addressString += (location.zip ? location.zip : '');
      addressString += ' ';
      addressString += (location.city ? location.city : '');
    }
    return addressString;
  }

  getFromToString(): string {
    let fromToString = ' | ';
    if (this.eventInquiryHealthScreening.eventInquiry.startTime && this.eventInquiryHealthScreening.eventInquiry.endTime) {
      fromToString += this.eventInquiryHealthScreening.eventInquiry.startTime;
      fromToString += ' - ';
      fromToString += this.eventInquiryHealthScreening.eventInquiry.endTime;
    }
    return fromToString;
  }

  applyForEventClicked() {
    const title = this.translateService.instant('applicationForEvent');
    const text = this.translateService.instant('applicationForEventText');
    this.dialogService.openConfirmationDialog(title, text, () => this.applyForEvent());
  }

  applyForEvent() {
    this.eventApplcationService.apply(this.eventInquiryHealthScreening.id).subscribe(res => {
      this.showButtons = false;
    });
  }

}
