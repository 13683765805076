import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { EventService, SignoffDto } from "src/app/services/event.service";
import { FileMeta, FileService } from "src/app/services/file.service";
import { KeycloakUser } from "src/app/services/keycloak-connector.service";
import { ProfileService } from "src/app/services/profile.service";
import * as moment from "moment";

@Component({
  selector: "event-signoff",
  templateUrl: "./event-signoff.component.html",
  styleUrls: ["./event-signoff.component.scss"],
})
export class EventSignoffComponent implements OnInit {
  @Input() eventForm: UntypedFormGroup;
  user: KeycloakUser;
  isSignable: boolean = false;
  userRole: string;
  imageUrl: string;
  eventId: number;
  signatureSubmitted: boolean = false;
  sumbitButtonClicked: boolean = false;
  constructor(private fileService: FileService, private profileService: ProfileService, private eventService: EventService) {}

  ngOnInit() {
    this.userRole = localStorage.getItem("role");
    this.eventId = this.eventForm.get("id").value;
    const today = new Date();
    this.profileService.getMyProfile().subscribe((profile) => {
      this.user = profile.body;
      this.fileService.getFileMetasByUserAndType(this.user.id, "CUSTOMER_SIGNATURE_DOCUMENT").subscribe((res) => {
        if (res && res[0]) {
          const meta: FileMeta = res[0];
          this.imageUrl = this.fileService.createDecryptedRessourceUrl(meta.id);
          if (moment(today, "day").isAfter(this.eventForm.get("eventDate").value) && this.imageUrl) {
            this.isSignable = true;
          }
          if (this.userRole === "gib_customer" && this.isSignable) {
            let checkbox = this.eventForm.get("signoffConfirmation");
            if (checkbox.value !== true) {
              checkbox.enable();
              this.eventForm.get("signoffComment").enable();
            } else {
              this.signatureSubmitted = true;
            }
          }
        }
      });
    });
  }

  signoffClicked() {
    let dto: SignoffDto = new SignoffDto();
    dto.comment = this.eventForm.get("signoffComment").value;
    dto.eventId = this.eventId;
    this.eventService.signoffEvent(dto).subscribe((res) => {
      let checkbox = this.eventForm.get("signoffConfirmation");
      checkbox.disable();
      this.signatureSubmitted = true;
      this.sumbitButtonClicked = true;
      const today = new Date();
      this.eventForm.get("signoffConfirmationTimestamp").setValue(moment(today, "day").format("DD.MM.YYYY"));
    });
  }
}
