import { InternalNote, InternalNoteService } from "src/app/services/internal-note.service";
import { BookAgainService } from "./../../../services/bookAgainService";
import { CartService } from "./../../../services/cart.service";
import { Component, OnInit, ChangeDetectorRef, ViewChild, AfterContentChecked } from "@angular/core";
import { FormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { EventService } from "src/app/services/event.service";
import { GibDialogService } from "../../../components/dialogs/gib-dialog.service";
import { FormHelper } from "../../../helper/form.helper";
import { Event, EventInquiryHealthscreening } from "../../../services/event.service";
import { ProfileService } from "../../../services/profile.service";
import * as moment from "moment";
import { CustomValidators } from "src/app/utils/custom-validator";
import { EventInquiryFormComponent } from "../components/event-inquiry-form/event-inquiry-form.component";
import { moduleItemValidator } from "src/app/validators/module-item.validator";

@Component({
  selector: "event-inquiry-page",
  templateUrl: "./event-inquiry-page.component.html",
  styleUrls: ["./event-inquiry-page.component.scss"],
})
export class EventInquiryPageComponent implements OnInit, AfterContentChecked {
  eventForm: UntypedFormGroup = this.initFormGroup(new Event());
  internalNoteForm: UntypedFormGroup = this.initInternalNoteForm(new InternalNote());
  formSubmitted: boolean;
  showPrintButtons: boolean = false;
  channel: string;
  userRole: string;
  event: Event;

  buid: string;
  filename;
  downloadUrl;

  private _valid: boolean = true;

  @ViewChild("eventInquiryForm") eventInquiryForm: EventInquiryFormComponent;

  constructor(
    private eventService: EventService,
    private translateService: TranslateService,
    private formHelper: FormHelper,
    private dialogService: GibDialogService,
    private route: ActivatedRoute,
    private profileService: ProfileService,
    private router: Router,
    private cartService: CartService,
    private bookAgainService: BookAgainService,
    private internalNoteService: InternalNoteService,
    private customValidators: CustomValidators
  ) {}

  navigateBack() {
    window.history.back();
  }

  ngOnInit() {
    this.userRole = localStorage.getItem("role");

    this.handleCart();
    if (this.bookAgainService.eventForm) {
      this.loadEventData();
    }
    this.eventForm.get("eventDate").valueChanges.subscribe((res) => {
      const diffTillEvent = this.formHelper.calculateDateDiff(res);
      if (diffTillEvent <= 5 && !this.hasOnlyOnlineModules(this.eventService.mapFormToEvent(this.eventForm).eventHealthScreenings)) {
        this.eventForm.get("eventlocation").get("street").setValidators([Validators.required]);
        this.eventForm.get("eventlocation").get("number").setValidators([Validators.required]);
        this.eventForm.get("eventlocation").get("zip").setValidators([Validators.required]);
      } else {
        this.eventForm.get("eventlocation").get("street").setValidators([]);
        this.eventForm.get("eventlocation").get("number").setValidators([]);
        this.eventForm.get("eventlocation").get("zip").setValidators([]);
      }
      this.eventForm.get("eventlocation").get("street").updateValueAndValidity();
      this.eventForm.get("eventlocation").get("number").updateValueAndValidity();
      this.eventForm.get("eventlocation").get("zip").updateValueAndValidity();
    });
    this.eventForm.get("eventHealthScreenings").valueChanges.subscribe((res) => {
      if (this.eventForm.get("eventDate").value) {
        const diffTillEvent = this.formHelper.calculateDateDiff(this.eventForm.get("eventDate").value);
        if (diffTillEvent <= 5 && !this.hasOnlyOnlineModules(res)) {
          this.eventForm.get("eventlocation").get("street").setValidators([Validators.required]);
          this.eventForm.get("eventlocation").get("number").setValidators([Validators.required]);
          this.eventForm.get("eventlocation").get("zip").setValidators([Validators.required]);
        } else {
          this.eventForm.get("eventlocation").get("street").setValidators([]);
          this.eventForm.get("eventlocation").get("number").setValidators([]);
          this.eventForm.get("eventlocation").get("zip").setValidators([]);
        }
        this.eventForm.get("eventlocation").get("street").updateValueAndValidity();
        this.eventForm.get("eventlocation").get("number").updateValueAndValidity();
        this.eventForm.get("eventlocation").get("zip").updateValueAndValidity();
      }
    });

    this.eventForm.get("estimatedBookingConfirmationDate").setValidators([Validators.required]);
    this.eventForm.get("estimatedBookingConfirmationDate").updateValueAndValidity();

    // if (this.userRole === "gib_customer") {
    //   this.eventForm.get("estimatedBookingConfirmationDate").setValidators([Validators.required]);
    // }
  }

  ngAfterContentChecked(): void {
    if (this.eventInquiryForm) {
      this._valid = this.eventInquiryForm.itemsValid;
      this.eventForm.setValidators(moduleItemValidator(this._valid));
      this.eventForm.updateValueAndValidity();
    }
  }

  routeToHome() {
    this.router.navigate(["/"]);
  }

  loadEventData() {
    this.eventForm = this.bookAgainService.eventForm;
    this.bookAgainService.clearEventForm();
    this.eventForm.get("state").setValue("INITIAL");
    this.handleChannel();
  }

  initInternalNoteForm(internalNote: InternalNote): UntypedFormGroup {
    const internalNoteForm = this.internalNoteService.mapInternalNoteToForm(internalNote);
    return internalNoteForm;
  }

  initFormGroup(event: Event): UntypedFormGroup {
    const eventForm = this.eventService.mapEventToForm(event);
    eventForm.get("eventDate").setValidators([Validators.required, this.formHelper.isDateValid()]);
    eventForm.get("eventHealthScreenings").setValidators([Validators.required]);
    eventForm.get("eventHealthScreenings").updateValueAndValidity();
    eventForm.get("customerSalutation").setValidators([Validators.required]);
    eventForm.get("customerFirstname").setValidators([Validators.required]);
    eventForm.get("customerLastname").setValidators([Validators.required]);
    eventForm.get("customerPhone").setValidators([Validators.required]);
    eventForm.get("customerMail").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("customerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("eventlocation").get("type").setValidators([Validators.required]);
    eventForm.get("eventlocation").get("city").setValidators([Validators.required]);
    eventForm.get("bookingCopyRecepientMailTemp").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    eventForm.get("expectedAttendees").setValidators(this.formHelper.isValidNumber());
    return eventForm;
  }

  hasOnlyOnlineModules(modules: any[]) {
    for (const module of modules) {
      if (!module.onlineModule) {
        return false;
      }
    }
    return true;
  }

  handleCart() {
    if (this.cartService.hasItems()) {
      const healthscreeningArray: any[] = [];
      const event = new Event();
      for (const moduleSelection of this.cartService.getCart()) {
        const eihs = new EventInquiryHealthscreening();
        eihs.healthscreening = moduleSelection.hs;
        if (moduleSelection.hs.moduleType === "HEALTH_MODULE") {
          if (moduleSelection.online) {
            eihs.onlineModule = true;
          }
        } else {
          eihs.onlineModule = undefined;
        }
        eihs.moduleType = moduleSelection.hs.moduleType;
        eihs.healthScreeningTopic = moduleSelection.topic;
        healthscreeningArray.push(eihs);
      }
      event.eventHealthScreenings = healthscreeningArray;
      this.eventForm = this.initFormGroup(event);
      this.handleChannel();
    } else {
      this.handleChannel();
    }
  }

  handleChannel() {
    this.route.params.subscribe((params) => {
      this.channel = params["channel"];
      this.eventForm.get("channel").setValue(this.channel);
      if (this.channel === "FORM") {
        this.loadCustomerData();
      }
    });
  }

  loadCustomerData() {
    this.profileService.getMyProfile().subscribe((res) => {
      const customer = res.body;
      if (this.eventForm.get("customer") && this.eventForm.get("customer.id")) {
        this.eventForm.get("customer.id").setValue(customer.id);
      }
      this.eventService.prefillFormWithCustomerValues(this.eventForm, customer);
      this.eventService.disableCustomerData(this.eventForm);
    });
  }

  startProcessClicked() {
    const today = new Date();
    if (this.userRole === "gib_customer") {
      if (this.eventForm.get("eventDate") && moment(this.eventForm.get("eventDate").value).isAfter(today, "day")) {
        this.formHelper.isFormValidElseShowErrors(this.eventForm, "eventInquireStartFailedTitle", () => this.startProcess());
        this.cartService.clearCart();
      } else {
        const title = this.translateService.instant("dateInPastTitle");
        const text = this.translateService.instant("dateInPastText");
        this.dialogService.openDialog(title, text, "success");
      }
    } else {
      this.formHelper.isFormValidElseShowErrors(this.eventForm, "eventInquireStartFailedTitle", () => this.startProcess());
      this.cartService.clearCart();
    }
  }

  startProcess() {
    const event = this.eventService.mapFormToEvent(this.eventForm);
    const internalNote = this.internalNoteService.mapFormToInternalNote(this.internalNoteForm);
    this.eventService.startProcess(event).subscribe((res) => {
      this.buid = res.body.buid;
      this.formHelper.disableControls(this.eventForm);
      // disable separately again to throw the valuechange event here,
      // so the module selection component can handle the listener and disable the controls into the component itself
      this.eventForm.get("eventHealthScreenings").disable();
      this.formSubmitted = true;
      this.showPrintButtons = true;
      this.openSuccessfullyCreatedDialog();

      internalNote.id = res.body.daoID;
      if (res.body.estimatedBookingConfirmationDate) {
        internalNote.sendingOn = res.body.estimatedBookingConfirmationDate;
      }
      if (this.userRole === "gib_admin" || this.userRole === "gib_director" || this.userRole === "gib_employee") {
        this.internalNoteService.create(internalNote, this.userRole).subscribe((res) => {
          this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(res.body);
          this.formHelper.disableControls(this.internalNoteForm);
        });
      }
    });
  }

  openSuccessfullyCreatedDialog() {
    const title = this.translateService.instant("eventInquiryStartedTitle");
    let text;
    if (this.userRole === "gib_customer") {
      text = this.translateService.instant("eventInquiryStartedTextForCustomer");
      this.dialogService.openDialog(title, text, "success", () => {
        this.router.navigate(["/my-events"]);
      });
    } else {
      text = this.translateService.instant("eventInquiryStartedText");
      this.dialogService.openDialog(title, text, "success");
    }
  }

  bookEventAgain() {
    this.formSubmitted = false;
    this.showPrintButtons = false;
    this.eventService.copyEventForm(this.eventForm);
    this.formHelper.enableControls(this.eventForm);
    this.formHelper.enableControls(this.internalNoteForm);
    this.eventForm.get("eventHealthScreenings").enable();
    this.eventService.disableCustomerData(this.eventForm);
  }

  openDownloadPdfConfirmation() {
    const title = this.translateService.instant("downloadPdfTitle");
    const text = this.translateService.instant("downloadPdfText");
    if (this.translateService.currentLang === "en") {
      this.filename = "eventinformation.pdf";
    } else if (this.translateService.currentLang === "de") {
      this.filename = "veranstaltungsinformation.pdf";
    }
    this.dialogService.openConfirmationDialog(title, text, () => this.printFormToPdf(this.filename));
  }

  printFormToPdf(filename: string) {
    this.eventService.getSignOffPdfByBuid(this.buid).subscribe((res) => {
      const blob = new Blob([res], { type: " application/pdf" });
      this.downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = filename;
      a.href = this.downloadUrl;
      a.click();
      setTimeout(() => {
        window.URL.revokeObjectURL(this.downloadUrl);
        document.body.removeChild(a);
      }, 0);
    });
  }

  get itemsValid() {
    return this._valid;
  }
}
