import { Component, OnInit, Input, ViewChildren, QueryList, ChangeDetectorRef, AfterContentChecked, OnChanges, SimpleChanges } from "@angular/core";
import { ValueLabel } from "../value-label";
import { UntypedFormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormArray } from "@angular/forms";
import { HealthScreeningService, HealthScreening, HealthscreeningTopic } from "../../services/health-screening.service";
import { EventInquiryHealthscreening } from "../../services/event.service";
import { TranslateService } from "@ngx-translate/core";
import { Customer } from "../../services/customer.service";
import { GibModuleSelectionItemComponent } from "../gib-module-selection-item/gib-module-selection-item.component";
import { MatOptionSelectionChange } from "@angular/material/core";

export declare type ModuleType = "healthModule" | "webinar" | "activeBreak";
export declare type DeliveryType = "onsite" | "online";

@Component({
  selector: "gib-module-selection",
  templateUrl: "./gib-module-selection.component.html",
  styleUrls: ["./gib-module-selection.component.scss"],
})
export class GibModuleSelectionComponent implements OnInit, AfterContentChecked, OnChanges {
  _modulesControl: UntypedFormArray;

  @Input() set modulesControl(controlObj: UntypedFormArray) {
    this._modulesControl = controlObj;
    this.createSelectControl();
    this.selectHints(this.healthModuleOnsiteSelectControl, "healthModule", "onsite");
    this.selectHints(this.healthModuleOnlineSelectControl, "healthModule", "online");
    this.selectHints(this.webinarSelectControl, "webinar", null);
    this.selectHints(this.activeBreakSelectControl, "activeBreak", null);

    this._modulesControl.valueChanges.subscribe((res) => {
      // if (this.disableControls) {
      //   this.healthModuleOnsiteSelectControl.disable();
      //   this.healthModuleOnlineSelectControl.disable();
      //   this.webinarSelectControl.disable();
      //   this.activeBreakSelectControl.disable();
      // } else {
      if (this._modulesControl.disabled) {
        this.healthModuleOnsiteSelectControl.disable();
        this.healthModuleOnlineSelectControl.disable();
        this.webinarSelectControl.disable();
        this.activeBreakSelectControl.disable();
      } else {
        this.healthModuleOnsiteSelectControl.enable();
        this.healthModuleOnlineSelectControl.enable();
        this.webinarSelectControl.enable();
        this.activeBreakSelectControl.enable();
      }
      // }
    });
  }
  @Input() eventForm: UntypedFormGroup;
  @ViewChildren("item") items: QueryList<GibModuleSelectionItemComponent>;

  healthModuleOnsiteSelectControl: UntypedFormControl = new UntypedFormControl([]);
  healthModuleOnlineSelectControl: UntypedFormControl = new UntypedFormControl([]);
  webinarSelectControl: UntypedFormControl = new UntypedFormControl([]);
  activeBreakSelectControl: UntypedFormControl = new UntypedFormControl([]);

  topics: any[] = [];
  healthModuleOnsiteModuleGroups: any[] = [];
  healthModuleOnlineModuleGroups: any[] = [];
  webinarModuleGroups: any[] = [];
  activeBreakModuleGroups: any[] = [];

  healthModuleOnsiteModuleHints: ValueLabel[] = [];
  healthModuleOnlineModuleHints: ValueLabel[] = [];
  webinarModuleHints: ValueLabel[] = [];
  activeBreakModuleHints: ValueLabel[] = [];

  shownHints: string[] = [];
  shownOnlineHints: string[] = [];

  disableControls: boolean = false;

  constructor(private healthscreeningService: HealthScreeningService, private formBuilder: UntypedFormBuilder, private translateService: TranslateService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.eventForm && this.eventForm.get("customer") && this.eventForm.get("customer").get("companynameAlias") && this.eventForm.get("customer").get("companynameAlias").value) {
      this.createModuleOptions(this.eventForm.get("customer").get("companynameAlias").value, false);
    } else {
      this.createModuleOptions("", false);
    }
    this.createModuleHints("");
    this.loadTopics();
  }

  ngAfterContentChecked() {
    // this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {}

  get modulesControl(): UntypedFormArray {
    return this._modulesControl;
  }

  get itemsValid() {
    let valid: boolean = true;
    if (this.items && this.items.length > 0) {
      this.items.forEach((item) => {
        if (!item.isValid) {
          valid = false;
        }
      });
    } else {
      valid = false;
    }
    return valid;
  }

  createModuleHints(overrideCustomerCompanynameAlias: string) {
    this.healthscreeningService.createModuleHints(overrideCustomerCompanynameAlias).then((result) => {
      this.healthModuleOnsiteModuleHints = result;
      if (this._modulesControl.value) {
        this.selectHints(this.healthModuleOnsiteSelectControl, "healthModule", "onsite");
      }
    });
    this.healthscreeningService.createOnlineModuleHints(overrideCustomerCompanynameAlias).then((result) => {
      this.healthModuleOnlineModuleHints = result;
      if (this._modulesControl.value) {
        this.selectHints(this.healthModuleOnlineSelectControl, "healthModule", "online");
      }
    });
    this.healthscreeningService.createOnlineModuleHints(overrideCustomerCompanynameAlias).then((result) => {
      this.webinarModuleHints = result;
      if (this._modulesControl.value) {
        this.selectHints(this.webinarSelectControl, "healthModule", null);
      }
    });
    this.healthscreeningService.createOnlineModuleHints(overrideCustomerCompanynameAlias).then((result) => {
      this.activeBreakModuleHints = result;
      if (this._modulesControl.value) {
        this.selectHints(this.activeBreakSelectControl, "activeBreak", null);
      }
    });
  }

  selectHints(changedSelection: any, moduleType: ModuleType, deliveryType: DeliveryType) {
    this.shownHints = [];
    if (changedSelection && changedSelection.value && changedSelection.value.length > 0) {
      for (const value of changedSelection.value) {
        const values: string[] = value.split(":");
        const hsId: string = values[0];
        this.addHinIfNotEmpty(hsId, this.shownHints, moduleType, deliveryType);
      }
    }
  }

  getHint(hsId: string, collection: string): string {
    return this[collection].find((h) => h.value === hsId)?.label;
  }

  addHinIfNotEmpty(value: string, hints: string[], moduleType: ModuleType, deliveryType: DeliveryType) {
    const property = this[moduleType + (deliveryType ? deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) : "") + "ModuleHints"];
    if (property) {
      for (const hint of property) {
        if (+hint.value === +value && hint.label !== "") {
          hints.push(hint.label);
        }
      }
    }
  }

  loadTopics() {
    this.healthscreeningService.getTopics().subscribe((res) => {
      this.topics = res.body;
    });
  }

  createModuleOptions(overrideCustomerCompanynameAlias: string, clearValues: boolean) {
    this.healthscreeningService.createModuleOptionsGroupedByModuleTopic(overrideCustomerCompanynameAlias).then((data) => {
      const onsite = JSON.parse(JSON.stringify(data["HEALTH_MODULE"]));
      const online = JSON.parse(JSON.stringify(data["HEALTH_MODULE"]));
      const webinar = data["WEBINAR"] ? JSON.parse(JSON.stringify(data["WEBINAR"])) : [];
      const activeBreak = data["ACTIVE_BREAK"] ? JSON.parse(JSON.stringify(data["ACTIVE_BREAK"])) : [];

      onsite.forEach(function (o) {
        o["modules"] = o["modules"].filter((m) => m.data.onsite);
      });

      online.forEach(function (o) {
        o["modules"] = o["modules"].filter((m) => m.data.online);
      });

      this.healthModuleOnsiteModuleGroups = onsite;
      this.healthModuleOnlineModuleGroups = online;
      this.webinarModuleGroups = webinar;
      this.activeBreakModuleGroups = activeBreak;

      // clear previous selections (in case values were chosen before selecting a customer)
      if (clearValues) {
        this.healthModuleOnsiteSelectControl.setValue("");
        this.healthModuleOnlineSelectControl.setValue("");
        this.webinarSelectControl.setValue("");
        this.activeBreakSelectControl.setValue("");
        const eventHealthScreenings = this.eventForm.controls.eventHealthScreenings as FormArray;
        while (eventHealthScreenings.length !== 0) {
          eventHealthScreenings.removeAt(0);
        }
      }
    });

    // this.healthscreeningService.createModuleOptionsGroupedByTopic(false, overrideCustomerCompanynameAlias).then((result) => {
    //   this.healthModuleOnsiteModuleGroups = result;
    //   console.log("healthModuleOnsiteModuleGroups: ", result);
    //   this.healthscreeningService.createModuleOptionsGroupedByTopic(true, overrideCustomerCompanynameAlias).then((res) => {
    //     this.healthModuleOnlineModuleGroups = res;
    //     console.log("healthModuleOnlineModuleGroups: ", res);
    //   });
    // });
  }

  updateModuleOptions(customer: Customer, clearValues: boolean) {
    this.createModuleOptions(customer.companynameAlias, clearValues);
    this.createModuleHints(customer.companynameAlias);
  }

  updateModuleValues(changedSelection: any, moduleType: ModuleType, deliveryType: DeliveryType) {
    this.selectHints(changedSelection, moduleType, deliveryType);
  }

  getSelectedTopics(moduleType: ModuleType, deliveryType: DeliveryType) {
    const selectedTopics = [];
    for (const group of this[moduleType + (deliveryType ? deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) : "") + "ModuleGroups"]) {
      if (this.isTopicSelected(group.key, moduleType, deliveryType) && !selectedTopics.includes(group.key)) {
        selectedTopics.push(group.key);
      }
    }
    return selectedTopics;
  }

  private isTopicSelected(topic: string, moduleType: ModuleType, deliveryType: DeliveryType): boolean {
    for (const group of this[moduleType + (deliveryType ? deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) : "") + "ModuleGroups"]) {
      if (group.key === topic) {
        for (const option of group.modules) {
          for (const value of this[moduleType + (deliveryType ? deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) : "") + "SelectControl"].value) {
            const values: string[] = value.split(":");
            const hsId: number = +values[0];
            const topic: string = values[1];
            if (hsId === option.value && topic === option.data.topic) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }

  getSelectedModulesForTopic(topic: string, moduleType: ModuleType, deliveryType: DeliveryType) {
    const hsIds = [];
    const ids: Set<number> = new Set<number>();
    for (const group of this[moduleType + (deliveryType ? deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) : "") + "ModuleGroups"]) {
      if (group.key === topic) {
        for (const option of group.modules) {
          for (const value of this[moduleType + (deliveryType ? deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) : "") + "SelectControl"].value) {
            const values: string[] = value.split(":");
            const hsId: number = +values[0];
            const topic: string = values[1];
            if (hsId === option.value && topic === option.data.topic) {
              hsIds.push(hsId);
              ids.add(hsId);
            }
          }
        }
      }
    }

    // return hsIds;
    return Array.from(ids);
  }

  findModuleLabelByValue(value: string, moduleType: ModuleType, deliveryType: DeliveryType): string {
    for (const group of this[moduleType + (deliveryType ? deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) : "") + "ModuleGroups"]) {
      for (const option of group.modules) {
        if (value === option.value) {
          return option.label;
        }
      }
    }
    return "";
  }

  private getModule(id: number, topic: string, moduleType: ModuleType, deliveryType: DeliveryType): string {
    for (const group of this[moduleType + (deliveryType ? deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) : "") + "ModuleGroups"]) {
      for (const option of group.modules) {
        if (id === option.value && topic === option.data.topic) {
          return option;
        }
      }
    }
    return "";
  }

  getHealthModuleDefaultValue(id: number, topic: string, moduleType: ModuleType, deliveryType: DeliveryType) {
    const defaultValue = this.getDefaultValue(id, moduleType, deliveryType, topic);
    if (defaultValue) {
      return defaultValue;
    }
    const method: string = "get" + moduleType.charAt(0).toUpperCase() + moduleType.slice(1) + deliveryType.charAt(0).toUpperCase() + deliveryType.slice(1) + "Options";
    const options: ValueLabel[] = this[method](id, topic);
    if (options && options.length > 0) {
      const value = options[0].value.toString();
      return value;
    }
    return null;
  }

  createSelectControl() {
    const onsiteSelectControlValue = [];
    const onlineSelectControlValue = [];
    const webinarSelectControlValue = [];
    const activeBreakSelectControlValue = [];

    if (this._modulesControl.value) {
      for (const ehs of this._modulesControl.value) {
        const hs = ehs.healthscreening;
        if (hs) {
          const id: string = hs.id + ":" + ehs.healthScreeningTopic;
          if (ehs.moduleType === "HEALTH_MODULE") {
            var onlineModuleRegExp = new RegExp(ehs.onlineModule);
            const isOnlineModule: boolean = onlineModuleRegExp.test("true");
            if (isOnlineModule) {
              onlineSelectControlValue.push(id);
            } else {
              onsiteSelectControlValue.push(id);
            }
          } else if (ehs.moduleType === "WEBINAR") {
            webinarSelectControlValue.push(id);
          } else if (ehs.moduleType === "ACTIVE_BREAK") {
            activeBreakSelectControlValue.push(id);
          }
        }
      }
    }

    this.healthModuleOnsiteSelectControl.setValue(onsiteSelectControlValue);
    this.healthModuleOnlineSelectControl.setValue(onlineSelectControlValue);
    this.webinarSelectControl.setValue(webinarSelectControlValue);
    this.activeBreakSelectControl.setValue(activeBreakSelectControlValue);

    if (this._modulesControl.disabled) {
      this.disableControls = true;
      this.healthModuleOnsiteSelectControl.disable();
      this.healthModuleOnlineSelectControl.disable();
      this.webinarSelectControl.disable();
      this.activeBreakSelectControl.disable();
    } else {
      this.disableControls = false;
      this.healthModuleOnsiteSelectControl.enable();
      this.healthModuleOnlineSelectControl.enable();
      this.webinarSelectControl.enable();
      this.activeBreakSelectControl.enable();
    }
  }

  onHealthModuleSelectionChange(event: any, deliveryType: DeliveryType) {
    const state = this.eventForm.get("state").value;
    if (state === "INITIAL") {
      if (this._modulesControl.enabled) {
        const { value, selected } = event.source;
        const values: string[] = value.split(":");
        const id: number = +values[0];
        const topic: HealthscreeningTopic = values[1] as HealthscreeningTopic;
        if (selected) {
          const eventHealthScreenings = this._modulesControl;
          let index = eventHealthScreenings.controls.findIndex(
            (ehs) => ehs.value.moduleType === "HEALTH_MODULE" && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic && (ehs.value.onlineModule === (deliveryType === "online" ? true : false) || ehs.value.onlineModule === (deliveryType === "online" ? "true" : "false"))
          );
          // if module does not exist
          if (index === -1) {
            const eihs = new EventInquiryHealthscreening();
            const hs = new HealthScreening();
            const module = this.getModule(id, topic, "healthModule", deliveryType);
            hs.id = id;
            hs.moduleType = module["data"].moduleType;
            hs.topic = topic;
            hs[deliveryType] = true;
            eihs.healthscreening = hs;
            eihs[deliveryType + "Interval"] = +this.getHealthModuleDefaultValue(id, topic, "healthModule", deliveryType);
            eihs.moduleType = module["data"].moduleType;
            eihs.healthScreeningTopic = topic;
            eihs.onlineModule = deliveryType === "online" ? true : false;
            this._modulesControl.push(this.formBuilder.group(eihs));
          }
        } else {
          const eventHealthScreenings = this.eventForm.controls.eventHealthScreenings as FormArray;
          let index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === "HEALTH_MODULE" && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic && ehs.value.healthscreening[deliveryType]);
          while (index !== -1) {
            eventHealthScreenings.removeAt(index);
            index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === "HEALTH_MODULE" && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic && ehs.value.healthscreening[deliveryType]);
          }
        }
      }
    }
  }

  onHealthModuleUpdate(event: { id: number; topic: string; amount: number; value: number }, deliveryType: DeliveryType) {
    const state = this.eventForm.get("state").value;
    if (state === "INITIAL") {
      const { id, amount, value } = event;
      const topic: HealthscreeningTopic = event.topic as HealthscreeningTopic;
      const eventHealthScreenings = this.eventForm.controls.eventHealthScreenings as FormArray;
      if (amount && amount > 0) {
        let index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === "HEALTH_MODULE" && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic && ehs.value.healthscreening[deliveryType]);
        while (index !== -1) {
          eventHealthScreenings.removeAt(index);
          index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === "HEALTH_MODULE" && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic && ehs.value.healthscreening[deliveryType]);
        }
        for (let i = 0; i < amount; i++) {
          const eihs = new EventInquiryHealthscreening();
          const hs = new HealthScreening();
          const module = this.getModule(id, topic, "healthModule", deliveryType);
          hs.id = id;
          hs.moduleType = module["data"].moduleType;
          hs.topic = topic;
          hs[deliveryType] = true;
          eihs.healthscreening = hs;
          eihs[deliveryType + "Interval"] = value;
          eihs.onlineModule = deliveryType === "online" ? true : false;
          eihs.healthScreeningTopic = topic;
          eihs.moduleType = module["data"].moduleType;
          this._modulesControl.push(this.formBuilder.group(eihs));
        }
      } else {
        let index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === "HEALTH_MODULE" && ehs.value.healthscreening.id === id && ehs.value.healthscreening.topic === topic && ehs.value.healthscreening[deliveryType]);
        while (index !== -1) {
          eventHealthScreenings.removeAt(index);
          index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === "HEALTH_MODULE" && ehs.value.healthscreening.id === id && ehs.value.healthscreening.topic === topic && ehs.value.healthscreening[deliveryType]);
        }
      }
    }
  }

  getDefaultValue(id: number, moduleType: ModuleType, deliveryType: DeliveryType, topic: string) {
    for (const ehs of this._modulesControl.value) {
      const hs = ehs.healthscreening;
      var onlineModuleRegExp = new RegExp(ehs.onlineModule);
      const isOnlineModule: boolean = onlineModuleRegExp.test("true");
      if (moduleType === "healthModule" && ehs.moduleType === "HEALTH_MODULE" && hs.id === id && ehs.healthScreeningTopic === topic) {
        if (deliveryType === "online" && isOnlineModule) {
          return ehs.onlineInterval;
        } else if (deliveryType === "onsite" && !isOnlineModule) {
          return ehs.onsiteInterval;
        }
      } else if (moduleType === "webinar" && ehs.moduleType === "WEBINAR" && hs.id === id && ehs.healthScreeningTopic === topic) {
        if (ehs.onlineModule !== null) {
          if (ehs.onlineModule) {
            return isOnlineModule ? "online" : "onsite";
          } else {
            return "onsite";
          }
        } else {
          return null;
        }
      } else if (moduleType === "activeBreak" && ehs.moduleType === "ACTIVE_BREAK" && hs.id === id && ehs.healthScreeningTopic === topic) {
        if (ehs.onlineModule !== null) {
          if (ehs.onlineModule) {
            return isOnlineModule ? "online" : "onsite";
          } else {
            return "onsite";
          }
        } else {
          return null;
        }
      }
    }
    return "";
  }

  getDefaultAmount(id: number, topic: string, moduleType: ModuleType, deliveryType: DeliveryType) {
    let count = 0;
    for (const ehs of this._modulesControl.value) {
      const hs = ehs.healthscreening;
      if (moduleType === "healthModule" && ehs.moduleType === "HEALTH_MODULE" && hs.id === id && ehs.healthScreeningTopic === topic) {
        var onlineModuleRegExp = new RegExp(ehs.onlineModule);
        const isOnlineModule: boolean = onlineModuleRegExp.test("true");
        if (deliveryType === "online" && isOnlineModule) {
          count++;
        } else if (deliveryType === "onsite" && !isOnlineModule) {
          count++;
        }
      } else if (moduleType === "webinar" && ehs.moduleType === "WEBINAR" && hs.id === id && ehs.healthScreeningTopic === topic) {
        count++;
      } else if (moduleType === "activeBreak" && ehs.moduleType === "ACTIVE_BREAK" && hs.id === id && ehs.healthScreeningTopic === topic) {
        count++;
      }
    }
    return count > 0 ? count : 1;
  }

  // Onsite
  getHealthModuleOnsiteOptions(id: number, topic: string): ValueLabel[] {
    const module = this.getModule(id, topic, "healthModule", "onsite");
    const options: ValueLabel[] = [];
    if (module["data"] && module["data"].recommendedOnsiteInterval) {
      const value = module["data"].recommendedOnsiteInterval;
      if (module["data"] && module["data"].alternativeOnsiteInterval) {
        options.push({ label: this.translateService.instant("minuteRecommended", { value }), value });
      } else {
        options.push({ label: this.translateService.instant("minute", { value }), value });
      }
    }
    if (module["data"] && module["data"].alternativeOnsiteInterval) {
      const value = module["data"].alternativeOnsiteInterval;
      options.push({ label: this.translateService.instant("minute", { value }), value: value });
    }
    return options;
  }

  // Online
  getHealthModuleOnlineOptions(id: number, topic: string): ValueLabel[] {
    const module = this.getModule(id, topic, "healthModule", "online");
    const options: ValueLabel[] = [];
    if (module["data"] && module["data"].recommendedOnlineInterval) {
      const value = module["data"].recommendedOnlineInterval;
      options.push({ label: this.translateService.instant("minute", { value }), value });
    }
    return options;
  }

  // Webinar & Active Break
  getOptions(): ValueLabel[] {
    const options: ValueLabel[] = [];
    options.push({ label: this.translateService.instant("online"), value: "online" });
    options.push({ label: this.translateService.instant("onsite"), value: "onsite" });
    return options;
  }

  onSelectionChange(event: MatOptionSelectionChange, moduleType: ModuleType) {
    const { value, selected } = event.source;
    const state = this.eventForm.get("state").value;
    if (!selected && state === "INITIAL") {
      const values: string[] = value.split(":");
      const id = values[0];
      const topic = values[1];
      const mappedModuleType = moduleType === "webinar" ? "WEBINAR" : "ACTIVE_BREAK";
      const eventHealthScreenings = this.eventForm.controls.eventHealthScreenings as FormArray;
      let index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === mappedModuleType && ehs.value.healthscreening.id === +id && ehs.value.healthScreeningTopic === topic);
      while (index !== -1) {
        eventHealthScreenings.removeAt(index);
        index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === mappedModuleType && ehs.value.healthscreening.id === +id && ehs.value.healthScreeningTopic === topic);
      }
    }
  }

  onUpdate(event: { id: number; topic: string; amount: number; value: string }, moduleType: ModuleType) {
    const state = this.eventForm.get("state").value;
    if (state === "INITIAL") {
      const { id, amount, value } = event;
      const topic: HealthscreeningTopic = event.topic as HealthscreeningTopic;
      const eventHealthScreenings = this.eventForm.controls.eventHealthScreenings as FormArray;
      if (amount && amount > 0) {
        let index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === (moduleType === "webinar" ? "WEBINAR" : "ACTIVE_BREAK") && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic);
        while (index !== -1) {
          eventHealthScreenings.removeAt(index);
          index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === (moduleType === "webinar" ? "WEBINAR" : "ACTIVE_BREAK") && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic);
        }
        for (let i = 0; i < amount; i++) {
          const eihs = new EventInquiryHealthscreening();
          const hs = new HealthScreening();
          const module = this.getModule(id, topic, moduleType, null);
          hs.id = id;
          hs.moduleType = module["data"].moduleType;
          hs.topic = topic;
          hs[value] = true;
          eihs.healthscreening = hs;
          eihs.onlineModule = value === "online" ? true : false;
          eihs.moduleType = module["data"].moduleType;
          eihs.healthScreeningTopic = topic;
          this._modulesControl.push(this.formBuilder.group(eihs));
        }
      } else {
        let index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === (moduleType === "webinar" ? "WEBINAR" : "ACTIVE_BREAK") && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic);
        while (index !== -1) {
          eventHealthScreenings.removeAt(index);
          index = eventHealthScreenings.controls.findIndex((ehs) => ehs.value.moduleType === (moduleType === "webinar" ? "WEBINAR" : "ACTIVE_BREAK") && ehs.value.healthscreening.id === id && ehs.value.healthScreeningTopic === topic);
        }
      }
    }
  }
}
