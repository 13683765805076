<div class="row d-flex padding justify-content-end">
  <button type="button" class="btn btn-outline" routerLink="/">{{ "back" | translate }}</button>
</div>
<div class="row d-flex padding justify-content-between">
  <h2 *ngIf="type === 'INDOOR_OUTDOOR'">{{ "healthscreeningOverview" | translate }}</h2>
  <h2 *ngIf="type === 'ONLINE'">{{ "onlineHealthscreeningOverview" | translate }}</h2>
  <div class="d-flex actions">
    <mat-form-field>
      <input matInput [(ngModel)]="searchTerm" placeholder="{{ 'search' | translate }}" />
    </mat-form-field>
  </div>
</div>

<div class="row d-flex justify-content-between box">
  <div class="col-lg-3">
    <gib-checkbox id="MOVEMENT_SPINE_HEALTH" [label]="'MOVEMENT_SPINE_HEALTH'" [control]="filterData.get('MOVEMENT_SPINE_HEALTH')"></gib-checkbox>
  </div>
  <div class="col-lg-3">
    <gib-checkbox id="RELAX_STRESS_HANDLING" [label]="'RELAX_STRESS_HANDLING'" [control]="filterData.get('RELAX_STRESS_HANDLING')"></gib-checkbox>
  </div>
  <div class="col-lg-3">
    <gib-checkbox id="NUTRITION" [label]="'NUTRITION'" [control]="filterData.get('NUTRITION')"></gib-checkbox>
  </div>
  <div class="col-lg-3">
    <gib-checkbox id="SLEEP_AND_WORK" [label]="'SLEEP_AND_WORK'" [control]="filterData.get('SLEEP_AND_WORK')"></gib-checkbox>
  </div>
</div>
<br />

<div class="d-flex">
  <div class="row w-100 healthscreeningPage mobilePadding">
    <div class="col-12 col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4" *ngFor="let healthscreening of filteredHealthscreenings">
      <preview [healthScreeningObject]="healthscreening" (emitAddCart)="addModuleToCart($event)" (emitMoreInfo)="goToInfoPage($event)" [imageUrls]="healthscreening.imageUrls"></preview>
    </div>
  </div>
</div>
