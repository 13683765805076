<div class="d-flex justify-content-end" *ngIf="user?.role === 'gib_customer' || user?.role === 'gib_trainer'">
  <button type="button" class="btn btn-outline" routerLink="/">{{ "back" | translate }}</button>
</div>
<div class="row d-flex justify-content-between">
  <h2>{{ "myProfile" | translate }}</h2>
</div>

<mat-tab-group>
  <mat-tab label="{{ 'masterData' | translate }}">
    <mat-card>
      <mat-card-content>
        <employee-profile [formGroup]="form" [(editMode)]="editMode" *ngIf="profileType === 'employee'"></employee-profile>
        <customer-profile [form]="form" [(editMode)]="editMode" *ngIf="profileType === 'customer'"></customer-profile>
      </mat-card-content>
      <mat-card-footer>
        <button *ngIf="editMode" type="button" class="btn btn-primary" (click)="saveProfileClicked()">
          {{ "saveChanges" | translate }}
        </button>
      </mat-card-footer>
    </mat-card>
  </mat-tab>
  <mat-tab label="{{ 'documents' | translate }}">
    <mat-card>
      <mat-card-content>
        <gib-documents *ngIf="user" [userId]="user.id" [fileType]="'TRAINER_PROFILE_DOCUMENT'"></gib-documents>
      </mat-card-content>
    </mat-card>
  </mat-tab>
  <mat-tab label="{{ 'signature' | translate }}">
    <mat-card>
      <mat-card-content>
        <div class="row">
          <div class="col-sm-12">
            <gib-documents *ngIf="user" [userId]="user.id" [maxFileCount]="1" [fileType]="'CUSTOMER_SIGNATURE_DOCUMENT'"></gib-documents>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">{{ "signature_description" | translate }}</div>
        </div>
      </mat-card-content>
    </mat-card>
  </mat-tab>
</mat-tab-group>
