import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from '@ngx-translate/core';
import { GibDialogService } from '../../../../components/dialogs/gib-dialog.service';
import { Employee, EmployeeService } from '../../../../services/employee.service';
import { KeycloakConnectorService } from '../../../../services/keycloak-connector.service';
import { SelectOptions } from '../../../../utils/select-options';

@Component({
  selector: 'trainer-table',
  templateUrl: './trainer-table.component.html',
  styleUrls: ['./trainer-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TrainerTableComponent implements OnInit {
  sort;
  emps: Employee[];
  expandedElement: Employee | null;
  isExpandable = true;
  locations = SelectOptions.trainerLocations;
  canEdit = false;

  @Output() employeeSelected = new EventEmitter < Employee > ();
  @Output() addEmployeeClicked = new EventEmitter();
  @Output() refresh = new EventEmitter();

  @Input() set employees(employees: Employee[]) {
    this.emps = employees;
    this.dataSource = new MatTableDataSource(employees);
    this.dataSource.filterPredicate = this.customPredicate;
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
    this.applyAdvancedFilter();
  }

  get employees(): Employee[] {
    return this.emps;
  }

  displayedColumnsInitial: string[] = ['lastname', 'firstname', 'adress', 'phone', 'email', 'lastAppointedDate', 'setAppointments', 'edit', 'delete'];
  displayedColumns: string[] = this.displayedColumnsInitial;
  dataSource: MatTableDataSource < Employee > ;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) set content(content: ElementRef) {
    this.sort = content;
  }

  filterData: UntypedFormGroup = this.initFormGroup();

  constructor(private cdr: ChangeDetectorRef, private dialogService: GibDialogService,
    private translateService: TranslateService, private employeeService: EmployeeService,
    private keycloakConnector: KeycloakConnectorService, private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {
    this.filterData.valueChanges.subscribe(() => {
      this.applyAdvancedFilter();
    });
    const role = localStorage.role;
    if (role === 'gib_director' || role === 'gib_admin' || role === 'gib_employee') {
      this.canEdit = true;
    }
  }

  initFormGroup(): UntypedFormGroup {
    const fg = this.formBuilder.group({
      carDrivingLicense: [false, []],
      carKfz: [false, []],
      carDrivingLicenseCopy: [false, []],
      engPresentation: [false, []],
      engAdvice: [false, []],
      preventionCourse: [false, []],
      blacklisted: [false, []],
      showAllExceptInactiveTrainer: [true, []],
      location: [
        [],
        []
      ]
    });
    return fg;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      if (this.dataSource.paginator.hasPreviousPage()) {
        this.dataSource.paginator.firstPage();
        this.cdr.detectChanges();
      }
    }
  }

  applyAdvancedFilter() {
    if (!this.emps) {
      return;
    }
    const filteredEmployees = Array.from(this.emps);
    Object.keys(this.filterData.controls).forEach(key => {
      if (key === 'location') {
        if (this.filterData.get('location').value && this.filterData.get('location').value.length > 0) {
          const indexToRemove = [];
          for (let i = (filteredEmployees.length - 1); i >= 0; i--) {
            let selectedLocations = 0;
            for (const filterLocation of this.filterData.get('location').value) {
              if (filteredEmployees[i].location && filteredEmployees[i].location.includes(filterLocation)) {
                selectedLocations++;
              }
            }
            if (selectedLocations !== this.filterData.get('location').value.length) {
              indexToRemove.push(i);
            }
          }
          for (const i of indexToRemove) {
            filteredEmployees.splice(i, 1);
          }
        }
      } else if (key === 'showAllExceptInactiveTrainer') {
        if (this.filterData.get(key).value) {
          const indexToRemove = [];
          for (let i = (filteredEmployees.length - 1); i >= 0; i--) {
            if (filteredEmployees[i]['state'] === 'INACTIVE') {
              indexToRemove.push(i);
            }
          }
          for (const i of indexToRemove) {
            filteredEmployees.splice(i, 1);
          }
        }
      } else {
        if (this.filterData.get(key).value) {
          const indexToRemove = [];
          for (let i = (filteredEmployees.length - 1); i >= 0; i--) {
            if (filteredEmployees[i][key] !== true) {
              indexToRemove.push(i);
            }
          }
          for (const i of indexToRemove) {
            filteredEmployees.splice(i, 1);
          }
        }
      }
    });
    this.dataSource.data = filteredEmployees;
    this.cdr.detectChanges();
  }

  resetFilter() {
    Object.keys(this.filterData.controls).forEach(key => {
      this.filterData.get(key).setValue(false);
    });
    this.dataSource = new MatTableDataSource(this.emps);
    this.dataSource.filterPredicate = this.customPredicate;
    this.dataSource.paginator = this.paginator;
    if (this.sort) {
      this.dataSource.sort = this.sort;
    }
  }

  resetDisplayedColumns() {
    this.displayedColumns = this.displayedColumnsInitial;
    this.isExpandable = true;
  }

  addEmployee() {
    // this.displayedColumns = ['lastname', 'firstname', 'phone', 'edit', 'delete'];
    this.addEmployeeClicked.emit(null);
    this.expandedElement = null;
    // this.isExpandable = false;
  }

  selectEmployee(emp: Employee) {
    // this.displayedColumns = ['lastname', 'firstname', 'phone', 'edit', 'delete'];
    this.employeeSelected.emit(emp);
    this.expandedElement = null;
    // this.isExpandable = false;
  }

  openDeleteEmployeeConfirmation(emp: Employee) {
    const fullName = this.employeeService.getFullName(emp);
    const title = this.translateService.instant('USER_DELETE_CONFIRMATION_TITLE');
    const text = this.translateService.instant('USER_DELETE_CONFIRMATION_TEXT', { employeeName: fullName });
    this.dialogService.openConfirmationDialog(title, text, () => this.deleteEmployee(emp.id));
  }

  deleteEmployee(id: string) {
    this.employeeService.delete(id).subscribe(() => {
      this.keycloakConnector.deleteUser(id).subscribe(() => {
        this.refresh.emit(null);
      });
    });
  }

  customPredicate(data, filter): boolean {
    let cityFilter = false;
    if (data.addresses && data.addresses[0] && data.addresses[0].city) {
      cityFilter = data.addresses[0].city.toLowerCase().includes(filter);
    } else {
      cityFilter = false;
    }
    const lastnameFilter = (data.lastname ? data.lastname.toLowerCase().includes(filter) : false);
    const firstnameFilter = (data.firstname ? data.firstname.toLowerCase().includes(filter) : false);
    const phonenumberFilter = (data.phonenumber ? data.phonenumber.toLowerCase().includes(filter) : false);
    const mailFilter = (data.email ? data.email.toLowerCase().includes(filter) : false);
    const qualificationFilter = (data.qualifications ? data.qualifications.toLowerCase().includes(filter) : false);
    return lastnameFilter || firstnameFilter || phonenumberFilter || mailFilter || cityFilter || qualificationFilter;
  }

  setAppointmentsClicked(emp: Employee) {
    const fullName = this.employeeService.getFullName(emp);
    const title = this.translateService.instant('setAppointmentsConfirmationTitle');
    const text = this.translateService.instant('setAppointmentsConfirmationText', { employeeName: fullName });
    this.dialogService.openConfirmationDialog(title, text, () => this.setAppointments(emp));
  }

  setAppointments(emp: Employee) {
    this.employeeService.setAppointments(emp).subscribe(res => {
      this.refresh.emit(null);
    });
  }

}