import { Component, OnInit, Inject, AfterViewInit, ChangeDetectorRef, ElementRef, ViewChild, OnDestroy } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from "@angular/material/bottom-sheet";
import { MatDialog } from "@angular/material/dialog";
import { FormHelper } from "../../../../helper/form.helper";
import { EventService, Chat } from "../../../../services/event.service";
import { GibDialogService } from "../../../../components/dialogs/gib-dialog.service";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { InternalNoteService, InternalNote } from "src/app/services/internal-note.service";
import { BookAgainService } from "src/app/services/bookAgainService";
import { Router } from "@angular/router";
import { LockingDialogComponent } from "../locking-dialog/locking-dialog.component";
import { Lock, LockingService } from "src/app/services/locking.service";
import { Subscription } from "rxjs";
import { CustomValidators } from "src/app/utils/custom-validator";
import { UntypedFormGroup, UntypedFormControl, Validators } from "@angular/forms";

@Component({
  selector: "event-details-bottom-sheet",
  templateUrl: "./event-details-bottom-sheet.component.html",
  styleUrls: ["./event-details-bottom-sheet.component.scss"],
})
export class EventDetailsBottomSheetComponent implements OnInit, OnDestroy {
  selectedIndex;
  eventForm: UntypedFormGroup;
  internalNoteForm: UntypedFormGroup;
  isEditable = false;
  editMode = false;
  chatElements: Chat[];
  userRole = "";
  showSendScheduleButton = false;
  allowModuleReplace: boolean;
  showPrintButtons: boolean = true;
  buid: string;
  lockingSubscription: Subscription;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private bottomSheet: MatBottomSheet,
    private formHelper: FormHelper,
    private eventService: EventService,
    private dialogService: GibDialogService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private internalNoteService: InternalNoteService,
    private bookAgainService: BookAgainService,
    private router: Router,
    public dialog: MatDialog,
    private lockingService: LockingService,
    private customValidators: CustomValidators
  ) {}

  ngOnInit() {
    this.eventForm = this.data.eventForm;
    if (this.eventForm) {
      this.buid = this.eventForm.get("buid").value;
      this.addEmailValidators();
    }

    this.internalNoteForm = this.data.internalNoteForm;
    this.isEditable = this.data.isEditable;
    this.selectedIndex = this.data.selectedIndex;
    this.allowModuleReplace = this.data.allowModuleReplace;
    this.userRole = localStorage.getItem("role");
    this.showSendScheduleButton = this.checkForScheduleSentButton();
  }

  addEmailValidators() {
    this.eventForm.get("customerMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    this.eventForm.get("corporateCustomerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    this.eventForm.get("customerContactMail").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
    this.eventForm.get("bookingCopyRecepientMailTemp").setValidators([this.customValidators.email, this.customValidators.specialCharacterValidator]);
  }

  startEditMode() {
    const lock: Lock = {
      entityName: "event-details-bottom-sheet",
      id: this.selectedIndex,
      username: localStorage.getItem("userId"),
      timeout: 15 * 60,
    };

    this.lockingSubscription = this.lockingService.check(lock).subscribe((response) => {
      if (response.body) {
        const body: Lock = response.body;

        const dialogRef = this.dialog.open(LockingDialogComponent, {
          data: { lock: lock },
        });

        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.lockingSubscription = this.lockingService.release(body).subscribe();
            this.lockingSubscription = this.lockingService.lock(lock).subscribe();

            this.formHelper.enableControls(this.eventForm);
            this.formHelper.enableControls(this.internalNoteForm);
            for (const eihs of this.eventForm.get("eventHealthScreenings")["controls"]) {
              this.formHelper.disableControls(eihs.get("trainer"));
              if (eihs.get("scheduleEntries").value && eihs.get("scheduleEntries").value.length > 0) {
                this.formHelper.disableControlsByName(eihs, ["onlineModule"]);
              }
            }
            this.editMode = true;
            this.showPrintButtons = false;
          }
        });
      } else {
        // lock record
        this.lockingSubscription = this.lockingService.lock(lock).subscribe();

        this.formHelper.enableControls(this.eventForm);
        this.formHelper.enableControls(this.internalNoteForm);
        for (const eihs of this.eventForm.get("eventHealthScreenings")["controls"]) {
          this.formHelper.disableControls(eihs.get("trainer"));
          if (eihs.get("scheduleEntries").value && eihs.get("scheduleEntries").value.length > 0) {
            this.formHelper.disableControlsByName(eihs, ["onlineModule"]);
          }
        }
        this.editMode = true;
        this.showPrintButtons = false;
      }
    });
  }

  bookAgain() {
    const bookAgainForm = this.eventService.copyEventForm(this.eventService.mapEventToForm(this.eventService.mapFormToEvent(this.eventForm)));
    this.bookAgainService.setEventForm(bookAgainForm);
    this.bottomSheet.dismiss();
    this.router.navigate(["/event-booking-page"]);
  }

  save() {
    for (const scheduleEntry of this.eventForm.get("eventHealthScreenings").get(this.selectedIndex).get("scheduleEntries")["controls"]) {
      if (!this.isScheduleEntryValid(scheduleEntry)) {
        const title = this.translateService.instant("scheduleEntryInvalidTitle");
        const text = [this.translateService.instant("scheduleEntryInvalidText")];
        this.dialogService.openErrorDialog(title, text);
        return;
      }
    }
    this.saveClicked();
  }

  isScheduleEntryValid(scheduleEntry: any) {
    if (scheduleEntry.get("email").value !== "" && scheduleEntry.get("email").value !== null && !scheduleEntry.valid) {
      return false;
    }
    if (scheduleEntry.get("firstname").value !== "" && scheduleEntry.get("firstname").value !== null && !scheduleEntry.valid) {
      return false;
    }
    if (scheduleEntry.get("lastname").value !== "" && scheduleEntry.get("lastname").value !== null && !scheduleEntry.valid) {
      return false;
    }
    return true;
  }

  saveClicked() {
    const event = this.eventService.mapFormToEvent(this.eventForm);
    const internalNote = this.internalNoteService.mapFormToInternalNote(this.internalNoteForm);

    this.eventService.update(event).subscribe((res) => {
      this.editMode = false;
      this.showPrintButtons = true;
      this.eventForm = this.eventService.mapEventToForm(res.body);
      this.eventForm.addControl("customerContactSearch", new UntypedFormControl(""));
      this.formHelper.disableControls(this.eventForm);
      // disable separately again to throw the valuechange event here,
      // so the module selection component can handle the listener and disable the controls into the component itself
      this.eventForm.get("eventHealthScreenings").disable();
      this.showSendScheduleButton = this.checkForScheduleSentButton();

      internalNote.id = res.body.id;
      this.internalNoteService.update(internalNote, this.userRole).subscribe((res) => {
        this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(res.body);
        this.formHelper.disableControls(this.internalNoteForm);
        this.cdr.detectChanges();
      });
      const lock: Lock = {
        entityName: "event-details-bottom-sheet",
        id: this.selectedIndex,
        username: localStorage.getItem("userId"),
        timeout: 15 * 60,
      };
      this.lockingService.release(lock).subscribe();
    });
  }

  cancel() {
    this.eventService.findById(this.eventForm.get("id").value).subscribe((res) => {
      this.editMode = false;
      this.showPrintButtons = true;
      this.eventForm = this.eventService.mapEventToForm(res.body);
      this.formHelper.disableControls(this.eventForm);
      // disable separately again to throw the valuechange event here,
      // so the module selection component can handle the listener and disable the controls into the component itself
      this.eventForm.get("eventHealthScreenings").disable();
      this.cdr.detectChanges();

      this.internalNoteService.getById(this.eventForm.get("id").value, this.userRole).subscribe((res) => {
        let internalNote = res.body;
        if (internalNote === null) {
          internalNote = new InternalNote();
        }
        this.internalNoteForm = this.internalNoteService.mapInternalNoteToForm(internalNote);
        this.formHelper.disableControls(this.internalNoteForm);
      });
      const lock: Lock = {
        entityName: "event-details-bottom-sheet",
        id: this.selectedIndex,
        username: localStorage.getItem("userId"),
        timeout: 15 * 60,
      };
      this.lockingService.release(lock).subscribe();
    });
  }

  sendSchedule() {
    const eventId = this.eventForm.get("id").value;
    this.eventService.sendSchedules(eventId).subscribe((res) => {
      this.showSendScheduleButton = false;
      this.cdr.detectChanges();
      this.openScheduleSentSuccessDialog();
    });
  }

  sendSchedulesClicked() {
    const title = this.translateService.instant("sendSchedule");
    const text = this.translateService.instant("sendSchedulesText");
    this.dialogService.openConfirmationDialog(title, text, () => this.sendSchedule());
  }

  openScheduleSentSuccessDialog() {
    const title = this.translateService.instant("schedulesSentTitle");
    const text = this.translateService.instant("scheduleSentText");
    this.dialogService.openDialog(title, text, "success");
  }

  checkForScheduleSentButton() {
    if (this.eventForm && this.eventForm.get("lastUpdated") && this.eventForm.get("scheduleEmailTimestamp")) {
      const lastUpdated = this.eventForm.get("lastUpdated").value;
      const scheduleEmailTimestamp = this.eventForm.get("scheduleEmailTimestamp").value;
      if (lastUpdated && scheduleEmailTimestamp) {
        if (moment(lastUpdated) > moment(scheduleEmailTimestamp)) {
          return true;
        }
      } else {
        return false;
      }
    }
    return false;
  }

  closeBottomSheet() {
    this.bottomSheet.dismiss();
  }
  ngOnDestroy() {
    if (this.lockingSubscription) {
      this.lockingSubscription.unsubscribe();
    }
  }
}
