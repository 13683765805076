import { Injectable } from "@angular/core";
import { HttpConfig } from "../utils/authentication/auth-interceptor";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { EnvService } from "./env.service";

@Injectable()
export class EventApplicationService {
  config: HttpConfig = {
    headers: new HttpHeaders(),
    observe: "response",
  };
  token: string;

  constructor(private http: HttpClient, private envService: EnvService) {
    this.token = localStorage.getItem("token");
  }

  getAvailableApplications(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/event/application/getAvailableApplications", this.config);
  }

  apply(hsId: number): Observable<HttpConfig> {
    return this.http.post(this.envService.backendUrl + "/event/application/apply?hsId=" + hsId, {}, this.config);
  }

  getAppliedEmployeesForEventHealthScreening(hsId: number): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/event/application/getApplicationForEventInquiryHealthscreenings?hsId=" + hsId, this.config);
  }

  getMyApplications(): Observable<HttpConfig> {
    return this.http.get(this.envService.backendUrl + "/event/application/myApplications", this.config);
  }
}
