import { FormHelper } from "./../../../helper/form.helper";
import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ValueLabel } from "../../../components/value-label";
import { Employee, EmployeeService } from "../../../services/employee.service";
import { EmployeesTableComponent } from "../components/employees-table/employees-table.component";

@Component({
  selector: "employees-page",
  templateUrl: "./employees-page.component.html",
  styleUrls: ["./employees-page.component.scss"],
})
export class EmployeesPageComponent implements OnInit {
  @ViewChild("emptable", { static: true }) employeeTable: EmployeesTableComponent;

  employees: Employee[];
  showAddEmployeeCard = false;
  selectedEmployee: Employee;
  roles: ValueLabel[] = [];

  constructor(private employeeService: EmployeeService, private translateService: TranslateService, private formHelper: FormHelper) {}

  ngOnInit() {
    this.getUsers();
    this.getRoles();
  }

  getUsers() {
    this.employeeService.findByRoles(["gib_admin", "gib_director", "gib_employee"]).subscribe((res) => {
      this.selectedEmployee = null;
      this.employeeTable.resetDisplayedColumns();
      this.employees = res.body;
      this.employees = res.body.sort(this.formHelper.sortByLastnameAndFirstname);
    });
  }

  getRoles() {
    this.employeeService.getRoles().then((res) => {
      this.roles = res;
    });
  }

  setSelectedEmployee(emp: Employee) {
    this.selectedEmployee = emp;
    this.showAddEmployeeCard = true;
  }

  openAddEmployeeCard() {
    this.selectedEmployee = new Employee();
    this.showAddEmployeeCard = true;
  }
}
