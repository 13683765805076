<div class="row">
  <div class="col-sm-12 col-md-4">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{'FILTER' | translate}}">
    </mat-form-field>
  </div>

  <div class="col-sm-12 col-md-6">
    <gib-expansion-panel [title]="'advanced_filteroptions'">
      <div class="row">
        <div class="col-sm-4">
          <gib-checkbox id="drivingLicenseFilter" [label]="'DRIVING_LICENSE'" [control]="filterData.get('carDrivingLicense')"></gib-checkbox><br>
          <gib-checkbox id="carKfzFilter" [label]="'KFZ'" [control]="filterData.get('carKfz')"></gib-checkbox><br>
          <gib-checkbox id="drivingLicenseCopyFilter" [label]="'DRIVING_LICENSE_COPY'" [control]="filterData.get('carDrivingLicenseCopy')"></gib-checkbox>
          <gib-multiselect [placeholder]="'place'" [control]="filterData.get('location')" [options]="locations"></gib-multiselect>
        </div>
        <div class="col-sm-4">
          <gib-checkbox id="presentationFilter" [label]="'PRESENTATION'" [control]="filterData.get('engPresentation')"></gib-checkbox>
          <gib-checkbox id="adviceFilter" [label]="'ADVICE'" [control]="filterData.get('engAdvice')"></gib-checkbox>
          <gib-checkbox id="showAllExceptInactiveTrainerFilter" [label]="'showAllExceptInactiveTrainerFilter'" [control]="filterData.get('showAllExceptInactiveTrainer')"></gib-checkbox>
        </div>
        <div class="col-sm-4">
          <gib-checkbox id="preventionCourseFilter" [label]="'preventionCourse'" [control]="filterData.get('preventionCourse')"></gib-checkbox>
          <gib-checkbox id="blacklistFilter" [label]="'blacklisted'" [control]="filterData.get('blacklisted')"></gib-checkbox>
        </div>
        <div class="col-sm-12">
          <button (click)="resetFilter()" class="btn btn-primary resetFilter"> {{ 'resetFilter' | translate }} </button>
        </div>
      </div>
    </gib-expansion-panel>
    <br>
  </div>

  <div class="col-sm-12 col-md-2 mb-2">
    <a *ngIf="canEdit" class="btn btn-secondary add-employee-btn" (click)="addEmployee()">+</a>
  </div>


  <div class="col-sm-12">
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows>
        <ng-container matColumnDef="lastname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'lastname' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.lastname}} </td>
        </ng-container>

        <ng-container matColumnDef="firstname">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'firstname' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.firstname}} </td>
        </ng-container>

        <ng-container matColumnDef="adress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'location' | translate}} </th>
          <td mat-cell *matCellDef="let row">{{(row.addresses && row.addresses[0]) ? row.addresses[0].city : ' '}}
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'phonenumber' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.phonenumber}}</td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'email' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.email}}</td>
        </ng-container>

        <ng-container matColumnDef="lastAppointedDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'appointment' | translate}} </th>
          <td mat-cell *matCellDef="let row"> {{row.lastAppointedDate | amDateFormat: 'DD.MM.YYYY HH:mm'}}</td>
        </ng-container>

        <ng-container matColumnDef="setAppointments">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
            <i *ngIf="canEdit" class="material-icons" [ngbTooltip]="'submitAppointments' | translate" (click)="setAppointmentsClicked(row)">assignment_turned_in</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="edit">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
            <i *ngIf="canEdit" class="material-icons" [ngbTooltip]="'EDIT' | translate" (click)="selectEmployee(row)">edit</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let row" class="actions-col" (click)="$event.stopPropagation()">
            <i *ngIf="canEdit" class="material-icons" [ngbTooltip]="'DELETE' | translate" (click)="openDeleteEmployeeConfirmation(row)">delete</i>
          </td>
        </ng-container>

        <ng-container matColumnDef="expandedDetail">
          <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
            <div class="customer-details" [@detailExpand]="element == expandedElement && isExpandable ? 'expanded' : 'collapsed'">
              <trainer-table-details [employee]="element"></trainer-table-details>
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.example-expanded-row]="expandedElement === row" (click)="expandedElement = expandedElement === row ? null : row"></tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="customer-detail-row"></tr>
      </table>
      <mat-paginator [pageSize]="100" [pageSizeOptions]="[10, 50, 100, 200]"></mat-paginator>
    </div>
  </div>
</div>