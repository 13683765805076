import { RouterEffects } from "./router.effect";
import { ActiveEventEffects } from "./active-event.effects";
import { CancelledEventEffects } from "./cancelled-event.effects";
import { InactiveEventEffects } from "./inactive-event.effects";

export const effects: any[] = [RouterEffects, ActiveEventEffects, CancelledEventEffects, InactiveEventEffects];

export * from "./router.effect";
export * from "./active-event.effects";
export * from "./cancelled-event.effects";
export * from "./inactive-event.effects";
