import { Component, EventEmitter, Input, Output, OnInit, ChangeDetectionStrategy, OnChanges, SimpleChanges } from "@angular/core";
import { ValueLabel } from "../value-label";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "gib-module-selection-item",
  styleUrls: ["gib-module-selection-item.component.scss"],
  template: `
    <div class="row">
      <div class="col-xl-6">
        <div class="row align-items-start">
          <div class="col-2" style="padding-right: 0px">
            <mat-form-field style="text-align: right" class="gib-mat-border-padding-fix">
              <input matInput [formControl]="amountControl" [disabled]="amountControl.disabled" />
            </mat-form-field>
          </div>
          <div class="col-10" style="padding-left: 3px">
            <span>x</span>
            {{ module.label }}
          </div>
          <div class="col-2" *ngif="false">
            <mat-icon *ngIf="module.hint" svgIcon="gib_tooltip" class="material-icons customTooltip" style="height: 30px !important;" [ngbTooltip]="module.hint" placement="left"> </mat-icon>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <gib-radio-button-group *ngIf="valueControl && (valueControl.valid || valueControl.disabled)" label=" " [control]="valueControl" [valueLabels]="_options" [showRequired]="false"></gib-radio-button-group>
        <gib-radio-button-group-orange *ngIf="valueControl && !valueControl.valid && !valueControl.disabled" label=" " [control]="valueControl" [valueLabels]="_options" [showRequired]="false"></gib-radio-button-group-orange>
      </div>
    </div>
  `,
})
export class GibModuleSelectionItemComponent implements OnInit, OnChanges {
  @Input() module: any;
  @Input() options: ValueLabel[];
  @Input() defaultValue: string;
  @Input() disabled: boolean;

  @Output() update: EventEmitter<{ id: number; topic: string; amount: number; value: number }> = new EventEmitter();

  private _control: FormGroup;
  _options: ValueLabel[];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this._options = this.options;
    this._control = this.fb.group({
      value: [{ value: this.defaultValue ? "" + this.defaultValue : "", disabled: this.disabled }, this._options && this._options.length > 0 ? [Validators.required] : []],
      amount: [{ value: this.module.defaultAmount, disabled: this.disabled }, Validators.required],
    });
    this._control.valueChanges.subscribe((changes) => {
      const event: { id: number; topic: string; amount: number; value: number } = {
        id: this.module.id,
        topic: this.module.topic,
        amount: this.amountControl.value,
        value: this.valueControl.value,
      };
      this.update.emit(event);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.disabled) {
      const { currentValue, previousValue } = changes.disabled;
      if (!previousValue && currentValue) {
        if (this.amountControl && this.valueControl) {
          this.amountControl.disable({ emitEvent: false });
          this.valueControl.disable({ emitEvent: false });
        }
      } else if (!currentValue && previousValue) {
        if (this.amountControl && this.valueControl) {
          this.amountControl.enable({ emitEvent: false });
          this.valueControl.enable({ emitEvent: false });
        }
      }
    }
  }

  get valueControl() {
    if (this._control) {
      return this._control.get("value");
    }
    return null;
  }

  get amountControl() {
    if (this._control) {
      return this._control.get("amount");
    }
    return null;
  }

  get isValid() {
    return this.valueControl.valid && this.amountControl.valid;
  }
}
