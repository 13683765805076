import { Component, Input, OnInit } from '@angular/core';
import { EventInquiryHealthscreening } from '../../../../services/event.service';

@Component({
  selector: 'running-application',
  templateUrl: './running-application.component.html',
  styleUrls: ['./running-application.component.sass']
})
export class RunningApplicationComponent implements OnInit {

  @Input() eventInquiryHealthScreening: EventInquiryHealthscreening;

  constructor() { }

  ngOnInit() {
  }

   createAddressString(): string {
    let addressString = '';
    if (this.eventInquiryHealthScreening.eventInquiry.eventlocation) {
      const location = this.eventInquiryHealthScreening.eventInquiry.eventlocation;
      addressString += (location.street ? location.street : '');
      addressString += ' ';
      addressString += (location.number ? location.number : '');
      addressString += ', ';
      addressString += (location.zip ? location.zip : '');
      addressString += ' ';
      addressString += (location.city ? location.city : '');
    }
    return addressString;
  }

  getFromToString(): string {
    let fromToString = ' | ';
    if (this.eventInquiryHealthScreening.eventInquiry.startTime && this.eventInquiryHealthScreening.eventInquiry.endTime) {
      fromToString += this.eventInquiryHealthScreening.eventInquiry.startTime;
      fromToString += ' - ';
      fromToString += this.eventInquiryHealthScreening.eventInquiry.endTime;
    }
    return fromToString;
  }

}
