import { Component, OnInit } from "@angular/core";
import { Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { CustomValidators } from "src/app/utils/custom-validator";
import { GibDialogService } from "../../../components/dialogs/gib-dialog.service";
import { FormHelper } from "../../../helper/form.helper";
import { Customer, CustomerService } from "../../../services/customer.service";
import { KeycloakConnectorService } from "../../../services/keycloak-connector.service";
import { SelectOptions } from "../../../utils/select-options";

@Component({
  selector: "customer-registration-page",
  templateUrl: "./customer-registration-page.component.html",
  styleUrls: ["./customer-registration-page.component.scss"],
})
export class CustomerRegistrationPageComponent implements OnInit {
  form = this.initForm();
  registrationStarted = false;
  salutationOptions = SelectOptions.salutationOptions;

  constructor(private dialogService: GibDialogService, private formHelper: FormHelper, private translateService: TranslateService, private customerService: CustomerService, private keycloakConnector: KeycloakConnectorService, private customValidators: CustomValidators) {}

  ngOnInit() {}

  initForm() {
    const customerForm = this.customerService.mapCustomerToForm(new Customer());
    customerForm.get("firstname").setValidators([Validators.required]);
    customerForm.get("lastname").setValidators([Validators.required]);
    customerForm.get("username").setValidators([Validators.required]);
    customerForm.get("email").setValidators([Validators.required, this.customValidators.email, this.customValidators.specialCharacterValidator]);
    customerForm.get("salutation").setValidators([Validators.required]);
    return customerForm;
  }

  startRegistration() {
    if (this.form.valid) {
      const customer = this.customerService.mapFormToCustomer(this.form);
      customer.addresses = [];
      this.keycloakConnector.createKeycloakCustomer(customer).subscribe({
        next: () => {
          const title = this.translateService.instant("customer_signup_success_title");
          const text = this.translateService.instant("customer_signup_success_text", { email: customer.email });
          this.dialogService.openDialog(title, text, "success");
          this.formHelper.disableControls(this.form);
          this.registrationStarted = true;
        },
      });
    } else {
      const errorMessages = this.formHelper.createFieldErrorMessage(this.form);
      this.dialogService.openErrorDialog("customer_signup_error_title", errorMessages);
    }
  }

  resetForm() {
    this.form = this.initForm();
    this.formHelper.enableControls(this.form);
    this.registrationStarted = false;
  }
}
