<mat-card>
  <mat-card-content>
    <div class="row">
      <div class="col-sm-12">
        <mat-label><b>{{eventInquiryHealthScreening.healthscreening.moduleName}}</b></mat-label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2">
        {{'eventDate' | translate}}:
      </div>
      <div class="col-sm-9">
        {{this.eventInquiryHealthScreening.eventInquiry.eventDate | amDateFormat:'DD.MM.YYYY' + getFromToString() }}
      </div>
    </div>
    <div class="row">
      <div class="col-sm-2">
        {{'event_location' | translate}}:
      </div>
      <div class="col-sm-4">
        {{ createAddressString()}}
      </div>
      <div class="col-sm-6">
      </div>
    </div>
  </mat-card-content>
</mat-card>
<br>
<hr>
<br>
