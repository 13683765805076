import { KeycloakService } from 'keycloak-angular';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  
  isLoggedIn = false;
  role = '';

  constructor(private keycloakService: KeycloakService) {}

  ngOnInit() {
    this.role = localStorage.getItem('role');
    this.keycloakService.isLoggedIn().then(res => {
      this.isLoggedIn = res;
    });
  }

  login() {
    this.keycloakService.login();
  }
}
